import {
    Link,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom'
import {
    PiCaretDownBold,
    PiCaretUpDown,
    PiMagnifyingGlass,
} from 'react-icons/pi'

import Logo from '@/components/logo/logo-mark-black'
import { cn } from '@/utils'
import { Button } from '@/components/ui/button'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from '@/components/ui/popover'
import { useAuth } from '@/hooks/auth'
import { Avatar, AvatarImage } from '../ui/avatar'
import { loadProjects, projectStore } from '@/stores/projects.store'
import { loadTeams, teamStore } from '@/stores/teams.store'
import { NewProjectDialogProvider } from '../providers/new-project-dialog-provider'
import { showNewProjectDialog } from '@/stores/ui-data.store'
import { switchTeam } from '@/services/teams'
import { useState, useMemo, useEffect } from 'react'
import { PopoverAnchor } from '@radix-ui/react-popover'

const TeamSelectMarkup = () => {
    const currentTeam = teamStore((state: any) => state.currentTeam)
    const currentProject = projectStore((state: any) => state.currentProject)
    const [showProjectSelectModal, setShowProjectSelectModal] = useState(false)

    const toggleProjectSelectModal = () => {
        setShowProjectSelectModal((prev) => !prev)
    }

    const markup = useMemo(() => {
        console.log('TeamSelectMarkup', currentTeam, currentProject)

        return (
            <div className="flex h-8 items-center space-x-3 rounded-3xl pr-1.5 text-left text-[14px] text-slate-500 transition-all">
                <span className="hidden text-gray-300 sm:block">/</span>
                <div className="flex items-center space-x-1.5">
                    <Link
                        to={`dashboard`}
                        className="flex items-center space-x-2"
                    >
                        <div className="rounded-full border border-slate-100 bg-white">
                            <img
                                src={currentTeam?.profile_photo_url}
                                className="h-6 w-6 rounded-full"
                                alt={currentTeam?.name}
                            />
                        </div>
                        <span className="hidden truncate sm:block sm:max-w-[230px]">
                            {currentTeam?.name}
                        </span>
                    </Link>
                    <Button
                        className="px-1"
                        variant="ghost"
                        onClick={toggleProjectSelectModal}
                    >
                        <PiCaretUpDown className="h-4 w-4" strokeWidth="5" />
                    </Button>
                </div>

                {currentProject && (
                    <>
                        <span className="hidden text-gray-300 sm:block">/</span>
                        <div className="flex items-center space-x-1.5">
                            <Link
                                to={`p/${currentProject?.id}`}
                                className="flex items-center space-x-2"
                            >
                                <div className="rounded-full border border-slate-100 bg-white">
                                    <img
                                        src={currentProject?.profile_photo_url}
                                        className="h-6 w-6 rounded-full"
                                        alt="Project Logo"
                                    />
                                </div>
                                <span className="hidden truncate sm:block sm:max-w-[230px]">
                                    {currentProject?.name}
                                </span>
                            </Link>
                            <Button
                                className="px-1"
                                variant="ghost"
                                onClick={toggleProjectSelectModal}
                            >
                                <PiCaretUpDown
                                    className="h-4 w-4"
                                    strokeWidth="5"
                                />
                            </Button>
                        </div>
                    </>
                )}
            </div>
        )
    }, [currentTeam, currentProject])

    useEffect(() => {
        console.log('TeamSelectMarkup useEffect', showProjectSelectModal)
    })

    return !currentTeam && !currentProject ? (
        <>Loading...</>
    ) : (
        <>
            <ProjectSelectMarkup
                open={showProjectSelectModal}
                onOpenChange={setShowProjectSelectModal}
            />

            {markup}
        </>
    )
}

const ProjectSelectMarkup = ({ open, onOpenChange }: any) => {
    const teams = teamStore((state: any) => state.teams)
    const projects = projectStore((state: any) => state.projects)
    const currentTeam = teamStore((state: any) => state.currentTeam)
    const currentProject = projectStore((state: any) => state.currentProject)
    const navigate = useNavigate()

    const handleTeamSwitch = (teamId: string) => {
        switchTeam(teamId).then(() => {
            onOpenChange(false)
            navigate(`/dashboard`)
        })
    }

    const switchProject = (projectId: string) => {
        onOpenChange(false)
        navigate(`/p/${projectId}`)
    }

    console.log('ProjectSelectMarkup', open)
    return (
        <Popover open={open} onOpenChange={onOpenChange}>
            <PopoverAnchor />
            <PopoverContent
                align="start"
                alignOffset={3}
                side="top"
                sideOffset={25}
                className="m-0 w-[480px] rounded-xl p-0 shadow-lg"
            >
                <div className="grid grid-cols-2 divide-x divide-slate-50 text-sm font-light text-slate-500">
                    <div className="">
                        <div className="h-12 rounded-tl-xl border-b border-slate-100 bg-slate-25">
                            <div className="my-auto flex h-full items-center space-x-2 px-4">
                                <PiMagnifyingGlass className="h-5 w-5" />
                                <input
                                    type="text"
                                    placeholder="Search Team"
                                    className="w-full bg-transparent outline-none"
                                />
                            </div>
                        </div>
                        <div className="space-y-4 p-4">
                            <div className="space-y-0.5">
                                {teams?.map((team: any) => {
                                    return (
                                        <button
                                            className={cn(
                                                'flex h-9 w-full items-center space-x-2 rounded-md px-1.5 py-1 hover:bg-slate-25 hover:text-slate-900',
                                                {
                                                    'bg-slate-50 text-slate-900':
                                                        team.id ===
                                                        currentTeam?.id,
                                                }
                                            )}
                                            key={team.id}
                                            onClick={() =>
                                                handleTeamSwitch(team.id)
                                            }
                                        >
                                            <img
                                                src={team?.profile_photo_url}
                                                className="h-6 w-6 rounded-full border bg-white"
                                                alt={team?.name}
                                            />
                                            <p className="truncate">
                                                {team.name}
                                            </p>
                                        </button>
                                    )
                                })}
                            </div>

                            <div>
                                <Button className="h-9 rounded-md" disabled>
                                    Add New
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="h-12 rounded-tr-xl border-b border-slate-100 bg-slate-25">
                            <div className="my-auto flex h-full items-center space-x-2 px-4">
                                <PiMagnifyingGlass className="h-5 w-5" />
                                <input
                                    type="text"
                                    placeholder="Search Project"
                                    className="w-full bg-transparent outline-none"
                                />
                            </div>
                        </div>
                        <div className="space-y-2 p-4">
                            <div className="max-h-80 space-y-0.5 overflow-y-auto pb-4">
                                {projects?.map((project: any) => {
                                    return (
                                        <button
                                            className={cn(
                                                'flex h-9 w-full items-center space-x-2 rounded-md px-2.5 py-1 hover:bg-slate-25 hover:text-slate-900',
                                                {
                                                    'bg-slate-50 text-slate-900':
                                                        project.id ===
                                                        currentProject?.id,
                                                }
                                            )}
                                            onClick={() =>
                                                switchProject(project.id)
                                            }
                                            key={project?.id}
                                        >
                                            <img
                                                src={project?.profile_photo_url}
                                                className="h-6 w-6 rounded-full border bg-white"
                                                alt={project?.name}
                                            />
                                            <p className="truncate">
                                                {project.name}
                                            </p>
                                        </button>
                                    )
                                })}
                            </div>
                            <div>
                                <Button
                                    className="h-9 w-full rounded-md"
                                    onClick={() => showNewProjectDialog()}
                                >
                                    Add New
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export function AppLayout() {
    const { logout, user } = useAuth({ middleware: 'auth' })

    const resetCurrentProject = projectStore(
        (state: any) => state.resetCurrentProject
    )

    const { projectId } = useParams()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const navigationLinks = [
        {
            label: 'Dashboard',
            path: '/dashboard',
            active: pathname.endsWith('/dashboard'),
        },
        {
            label: 'Settings',
            path: '/settings',
            active: pathname === '/settings',
        },
        {
            label: 'Teams',
            path: '/settings/teams',
            active: pathname.endsWith('/settings/teams'),
        },
        {
            label: 'Billing',
            path: '/settings/team/billing',
            active: pathname.endsWith('/settings/billing'),
        },
    ]

    const [showProjectSelection, setShowProjectSelection] = useState(false)

    useEffect(() => {
        if (pathname.startsWith('/setting')) {
            setShowProjectSelection(false)
        } else {
            setShowProjectSelection(true)
        }
    }, [pathname])

    useEffect(() => {
        if (!projectId) {
            resetCurrentProject()
        }
    }, [projectId])

    useEffect(() => {
        loadTeams()
        loadProjects()
    }, [])

    console.log('AppLayout rendered')

    return (
        <>
            <div
                className={cn('h-20', {
                    'sticky top-0 z-10 h-[60px] border bg-white': projectId,
                })}
            >
                <header className="h-full">
                    <div
                        className={cn(
                            'container mx-auto flex h-full w-full items-center justify-between',
                            {
                                container: !projectId,
                            }
                        )}
                    >
                        <div className="flex items-center space-x-2">
                            <Link to="/dashboard">
                                <Logo className="h-7 w-7" />
                            </Link>

                            {showProjectSelection && <TeamSelectMarkup />}

                            {!showProjectSelection && (
                                <p className="pl-2">Setting</p>
                            )}
                        </div>

                        {/* Right side menu */}
                        <div className="flex items-center space-x-3">
                            {/* <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                className="h-8 w-8 rounded-full px-0"
                                variant="outline"
                            >
                                <PiBellSimpleLight
                                    strokeWidth="1.5"
                                    className="h-5 w-5 text-gray-700"
                                />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            align="start"
                            alignOffset={1}
                            className="m-0 w-[480px] rounded-xl p-0 shadow-lg"
                        >
                            Place content for the popover here.
                        </PopoverContent>
                    </Popover> */}

                            <Popover>
                                <PopoverTrigger className="flex items-center space-x-2">
                                    <Avatar className="h-8 w-8 border">
                                        <AvatarImage
                                            src={user?.profile_photo_url}
                                        />
                                    </Avatar>
                                    {/* Icon down */}
                                    <PiCaretDownBold className="h-4 w-4 text-gray-500" />
                                </PopoverTrigger>

                                <PopoverContent
                                    alignOffset={1}
                                    className="w-64 rounded-xl p-0 text-sm text-slate-500 shadow-lg"
                                    align="end"
                                >
                                    <div className="border-b px-6 py-3">
                                        {user?.name}
                                    </div>

                                    <div className="divide-y divide-slate-50 pb-2">
                                        <div className="space-y-px py-2">
                                            {navigationLinks.map(
                                                ({ path, active, label }) => (
                                                    <Button
                                                        key={path}
                                                        variant="ghost"
                                                        onClick={() =>
                                                            navigate(path)
                                                        }
                                                        className={cn(
                                                            'w-full justify-start rounded-none px-6 font-light',
                                                            {
                                                                'bg-gray-100 text-slate-900':
                                                                    active,
                                                            }
                                                        )}
                                                    >
                                                        {label}
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                        <div className="space-y-px py-2">
                                            <Button
                                                variant="ghost"
                                                onClick={() => logout()}
                                                className="w-full justify-start rounded-none px-6 font-light"
                                            >
                                                Logout
                                            </Button>
                                        </div>
                                    </div>
                                </PopoverContent>
                            </Popover>
                        </div>
                    </div>
                </header>
            </div>

            <main className="w-full">
                <Outlet />
            </main>

            <NewProjectDialogProvider />
        </>
    )
}
