import { Button } from '@/components/ui/button'
import { updateProject } from '@/services/projects'
import { projectStore } from '@/stores/projects.store'
import { notifySuccess, notifyError } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Input } from '@/components/ui/input-field'

export function ProjectSettingsPage() {
    const currentProject = projectStore((state: any) => state.currentProject)
    const setCurrentProject = projectStore(
        (state: any) => state.setCurrentProject
    )

    const [projectName, setProjectName] = useState<string>('')

    useEffect(() => {
        setProjectName(currentProject?.name)
    }, [currentProject])

    const { mutate, isLoading } = useMutation({
        mutationKey: ['updateProject'],
        mutationFn: updateProject,
    })

    const handleUpdate = async (event: any) => {
        event.preventDefault()

        mutate(
            {
                ...currentProject,
                name: projectName,
            },
            {
                onSuccess: (data) => {
                    notifySuccess('Project updated successfully.')
                    setCurrentProject(data.data)
                },
                onError: (error) => {
                    console.error(error)
                    notifyError('Something went wrong. Please try again later.')
                },
            }
        )
    }

    return (
        <>
            <section>
                <div className="max-w-3xl">
                    <form>
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Project Name
                            </label>
                            <div className="mt-2">
                                <Input
                                    id="name"
                                    type="name"
                                    defaultValue={projectName}
                                    onChange={(event) =>
                                        setProjectName(event.target.value)
                                    }
                                    required
                                />
                            </div>
                        </div>

                        <div className="py-6">
                            <Button
                                type="submit"
                                onClick={handleUpdate}
                                disabled={isLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
