import { Button } from '@/components/ui/button'
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import { Input } from '@/components/ui/input-field'
import { useAuth } from '@/hooks/auth'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { TbEye, TbEyeOff } from 'react-icons/tb'
import GoogleSvgIcon from '@/assets/icons/google.svg'
import FacebookSvgIcon from '@/assets/icons/facebook.svg'
import { InputError } from '@/components/ui/input-error'
import { SessionStatus } from './components/session-status'
import { LoaderButton } from '@/components/ui/loader-button'
import { useConfig } from '@/hooks/config'

type Errors = {
    email?: string[]
    password?: string[]
}

export function LoginPage() {
    const { app } = useConfig()
    const { login } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/dashboard',
    })

    const params = useParams() as any

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [shouldRemember, setShouldRemember] = useState<boolean>(false)
    const [errors, setErrors] = useState<Errors>({})
    const [status, setStatus] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (params?.reset?.length > 0 && Object.keys(errors).length === 0) {
            setStatus(atob(params?.reset))
        } else {
            setStatus(null)
        }
    })

    const submitForm = async (event: any) => {
        event.preventDefault()

        setLoading(true)
        login({
            email,
            password,
            remember: shouldRemember,
            setErrors,
            setStatus,
        })
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    function togglePasswordVisibility() {
        setIsPasswordVisible((prevState) => !prevState)
    }

    const handleQuickLogin = () => {
        if (!app.isLocal()) return

        setLoading(true)
        login({
            email: 'alaminfirdows@gmail.com',
            password: 'password',
            remember: true,
            setErrors,
            setStatus,
        }).finally(() => setLoading(false))
    }

    return (
        <div className="relative flex min-h-[calc(100vh-80px)] flex-col items-center justify-center overflow-hidden">
            <div className="m-auto w-full lg:max-w-lg">
                <div className="mb-[30px] text-center">
                    <h1 className="mb-2 text-2xl font-semibold tracking-[-1px] text-slate-900 md:text-4xl">
                        Login
                    </h1>
                    <p className="mx-auto tracking-[-0.3px] text-slate-500">
                        Please enter your details to Login
                    </p>
                </div>

                {/* Session Status */}
                <SessionStatus className="mb-4" status={status} />

                <Card className="mx-auto max-w-md bg-white">
                    <form onSubmit={submitForm}>
                        <CardContent className="grid gap-4 p-6">
                            <div className="grid hidden grid-cols-2 gap-2">
                                <Button
                                    variant="outline"
                                    className="h-auto space-x-2 py-2 shadow-none"
                                >
                                    <img
                                        className="h-6"
                                        src={GoogleSvgIcon}
                                        alt="Google"
                                    />
                                    <span>Google</span>
                                </Button>

                                <Button
                                    variant="outline"
                                    className="h-auto space-x-2 py-2 shadow-none"
                                >
                                    <img
                                        className="h-6"
                                        src={FacebookSvgIcon}
                                        alt="Facebook"
                                    />
                                    <span>Facebook</span>
                                </Button>
                            </div>

                            {/* devider */}
                            <div className="relative flex hidden items-center py-2">
                                <div className="flex-grow border-t border-slate-200"></div>
                                <span className="mx-2 flex-shrink text-slate-500">
                                    or
                                </span>
                                <div className="flex-grow border-t border-slate-200"></div>
                            </div>

                            {/* Email */}
                            <div className="space-y-2">
                                <Label htmlFor="email">
                                    Email{' '}
                                    <span className="text-red-500">*</span>
                                </Label>
                                <Input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(event) =>
                                        setEmail(event.target.value)
                                    }
                                    required
                                    autoFocus
                                    placeholder="hello@chatever.ai"
                                    className="placeholder:text-slate-300"
                                />

                                <InputError
                                    messages={errors?.email}
                                    className="mt-2"
                                />
                            </div>

                            {/* Password */}
                            <div className="space-y-2">
                                <Label htmlFor="password">
                                    Password{' '}
                                    <span className="text-red-500">*</span>
                                </Label>
                                <div className="relative">
                                    <Input
                                        id="password"
                                        type={
                                            isPasswordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={password}
                                        onChange={(event) =>
                                            setPassword(event.target.value)
                                        }
                                        required
                                        autoComplete="current-password"
                                        placeholder="p@ssw0rd"
                                        className="placeholder:text-slate-300"
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {isPasswordVisible ? (
                                            <TbEyeOff className="h-5 w-5 text-slate-500 " />
                                        ) : (
                                            <TbEye className="h-5 w-5 text-slate-500 " />
                                        )}
                                    </button>
                                </div>

                                <InputError
                                    messages={errors?.password}
                                    className="mt-2"
                                />
                            </div>

                            <div className="flex items-center justify-between py-2 text-xs">
                                {/* remember */}
                                <div className="flex items-center space-x-2">
                                    <Checkbox
                                        checked={shouldRemember}
                                        onCheckedChange={(value) =>
                                            setShouldRemember(!!value)
                                        }
                                    />
                                    <label
                                        htmlFor="remember"
                                        className="cursor-pointer font-medium leading-none text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:opacity-70 "
                                    >
                                        Remember me
                                    </label>
                                </div>
                                {/* Forgot password */}
                                <Link to="/auth/forgot-password">
                                    Forgot password?
                                </Link>
                            </div>

                            <LoaderButton
                                type="submit"
                                className="w-full select-none py-5"
                                isLoading={loading}
                                disabled={loading}
                            >
                                Login
                            </LoaderButton>

                            {/* Quick Login */}
                            {app.isLocal() && (
                                <LoaderButton
                                    type="button"
                                    variant="ghost"
                                    onClick={handleQuickLogin}
                                    isLoading={loading}
                                    disabled={loading}
                                >
                                    Quick Login
                                </LoaderButton>
                            )}
                        </CardContent>
                    </form>

                    <CardFooter className="flex flex-col space-y-2 px-4">
                        <p className="flex items-center justify-center space-x-2 text-center text-sm">
                            <span className="text-slate-500">
                                Don't have an account?
                            </span>
                            <Link
                                to="/auth/register"
                                className=" cursor-pointer font-medium underline"
                            >
                                Create account
                            </Link>
                        </p>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}
