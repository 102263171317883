import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
} from '@/components/ui/select'
import { updateAssistant } from '@/services/projects'
import { Assistant } from '@/types'
import { notifySuccess, notifyError } from '@/utils'
import { Label } from '@radix-ui/react-label'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

export const SecurityContent = ({ assistant }: { assistant: Assistant }) => {
    const [formData, setFormData] = useState<Assistant>(assistant)

    const { mutate, isLoading } = useMutation({
        mutationKey: ['update-chatbot'],
        mutationFn: updateAssistant,
    })

    const onValueChange = (key: any, value: any) => {
        setFormData({
            ...formData,
            [key]: value,
        })
    }

    const handleSubmit = () => {
        mutate(
            {
                projectId: assistant.project_id,
                data: formData,
            },
            {
                onSuccess: (_) => {
                    notifySuccess('Chatbot updated successfully.')
                },
                onError: (_) => {
                    notifyError('Something went wrong. Please try again later.')
                },
            }
        )
    }

    return (
        <>
            <Card>
                <CardContent className="p-4">
                    <Label className="mb-2 block p-0 font-medium">
                        Visibility
                    </Label>

                    <div className="mb-4 w-1/2">
                        <Select
                            defaultValue={formData.visibility}
                            onValueChange={(value) =>
                                onValueChange('visibility', value)
                            }
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select visibility" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="Public">Public</SelectItem>
                                <SelectItem value="Private">Private</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-1 text-xs">
                        <p>
                            <span className="mr-1 font-semibold">private:</span>
                            No one can access your chatbot except you
                        </p>
                        <p>
                            <span className="mr-1 font-semibold">public:</span>
                            Other people can chat with your chatbot if you send
                            them the link. You can also embed it on your website
                            so your website visitors are able to use it.
                        </p>
                    </div>
                </CardContent>
            </Card>

            <div className="flex justify-end py-3">
                <Button
                    className="px-6 py-[7px] font-normal text-slate-25"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    Save
                </Button>
            </div>
        </>
    )
}
