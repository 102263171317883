import { axios } from "@/utils";

const getTeams = async () => {
    return axios.get("api/v1/teams").then((res) => res.data);
};

const getTeam = async (id: string) => {
    return axios.get(`api/v1/teams/${id}`).then((res) => res.data);
};

const switchTeam = async (id: string) => {
    return axios.post(`api/v1/teams/switch`, {
        team_id: id,
    }).then((res) => res.data);
}

export { getTeams, getTeam, switchTeam };
