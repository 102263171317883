import { getProject, getProjects } from '@/services/projects';
import { create } from 'zustand'

type State = {
    projects: Array<any>,
    currentProject: any,
}
type Actions = {
    setProjects: (projects: any) => void,
    setCurrentProject: (project: any) => void,
    resetCurrentProject: () => void,
}

export const projectStore = create<State & Actions>((set: any) => ({
    projects: [],
    currentProject: null,
    setProjects: (projects: any) => set(() => ({ projects: projects })),
    setCurrentProject: (project: any) => {
        console.log('Current project setting', project);
        set(() => ({ currentProject: project }))
    },
    resetCurrentProject: () => set(() => ({ currentProject: null })),
}))

export const loadProjects = async () => {
    const projects = await getProjects();

    projectStore.getState().setProjects(projects.data)
}

export const reloadCurrentProject = async () => {
    const project = await getProject(projectStore.getState().currentProject.id);

    projectStore.getState().setCurrentProject(project.data)
}

export const setEmbeddingStatus = async (status: string) => {
    const currentProject = projectStore.getState().currentProject;

    projectStore.getState().setCurrentProject({
        ...currentProject,
        embedding: {
            ...currentProject.embedding,
            status: status,
        }
    })
}
