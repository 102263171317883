import { getTeams } from '@/services/teams'
import { create } from 'zustand'

type State = {
    teams: Array<any>,
    currentTeam: any,
}
type Actions = {
    setTeams: (projects: any) => void,
    setCurrentTeam: (project: any) => void,
}

export const teamStore: any = create<State & Actions>((set: any) => ({
    teams: [],
    currentTeam: {},
    setTeams: (teams: any) => set((state: any) => state.teams = teams),
    setCurrentTeam: (team: any) => set((state: any) => state.currentTeam = team),
}))

export const loadTeams = async () => {
    if (teamStore.getState().teams.length > 0) {
        return;
    }

    const teams = await getTeams();

    teamStore.setState(() => ({
        teams: teams.data,
        currentTeam: teams.data[0],
    }))
}
