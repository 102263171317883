import { create } from 'zustand'

type State = {
    showNewProjectDialog: boolean,
}
type Actions = {
    setShowNewProjectDialog: (state: boolean) => void,
}

export const uiDataStore = create<State & Actions>((set: any) => ({
    showNewProjectDialog: false,
    setShowNewProjectDialog: (state: any) => set(() => ({ showNewProjectDialog: state })),
}))

export const showNewProjectDialog = () => {
    uiDataStore.setState(() => ({
        showNewProjectDialog: true,
    }))
}
