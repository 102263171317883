import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input-field'
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/textarea'
import { updateAssistant, updateAssistantIcons } from '@/services/projects'
import { Assistant } from '@/types'
import { notifyError, notifySuccess } from '@/utils'
import { Label } from '@radix-ui/react-label'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

type Props = {
    assistant: Assistant
}

const DefaultIcon = ({ style }: any) => {
    return (
        <div className="h-11 w-11 rounded-full bg-black p-2" style={style}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 139 134"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M137.96 7.06875C134.149 6.82878 131.111 3.80013 130.871 0C130.63 3.80013 127.593 6.82878 123.782 7.06875C127.593 7.30873 130.63 10.3373 130.871 14.1375C131.111 10.3373 134.149 7.30873 137.96 7.06875ZM123.782 16.3313C116.029 15.843 109.849 9.6813 109.36 1.95C108.87 9.6813 102.691 15.843 94.9375 16.3313C102.691 16.8195 108.87 22.9811 109.36 30.7125C109.849 22.9811 116.029 16.8195 123.782 16.3313ZM77.616 13.9688C81.9307 13.9688 85.4285 17.4665 85.4285 21.7812V22.9531C85.4285 33.7399 94.1729 42.4844 104.96 42.4844H114.062C118.377 42.4844 121.875 45.9822 121.875 50.2969V133.5L120.648 131.713C110.617 117.102 94.0462 116.186 76.3405 116.186H26.8842C12.0365 116.186 0 104.133 0 89.2644V40.8902C0 26.0219 12.0365 13.9688 26.8842 13.9688H77.616ZM129.16 19.5C129.492 24.7416 133.681 28.919 138.938 29.25C133.681 29.581 129.492 33.7584 129.16 39C128.828 33.7584 124.638 29.581 119.382 29.25C124.638 28.919 128.828 24.7416 129.16 19.5ZM93.9824 67.1602C90.5537 73.0788 85.6224 77.9932 79.6847 81.4098C73.7469 84.8263 67.0117 86.6248 60.1559 86.6248C53.3001 86.6248 46.5649 84.8263 40.6271 81.4098C34.6894 77.9932 29.7581 73.0788 26.3294 67.1602L25.7402 66.1432L34.3342 61.1982L34.9199 62.2093C37.477 66.6232 41.155 70.2891 45.5849 72.838C50.0148 75.3869 55.0402 76.729 60.1559 76.729C65.2716 76.729 70.297 75.3869 74.7269 72.838C79.1567 70.2891 82.8349 66.6232 85.3919 62.2093L85.9776 61.1982L94.5716 66.1432L93.9824 67.1602Z"
                    fill="white"
                />
            </svg>
        </div>
    )
}

export const AppearanceTabContent = ({ assistant }: Props) => {
    const [formData, setFormData] = useState<{
        name: string
        initial_messages: string[]
        suggested_messages: string[]
        luncher_position: string
        luncher_bg_color: string
        contact_message_color: string
    }>({
        name: assistant.name,
        initial_messages: assistant.initial_messages,
        suggested_messages: assistant.suggested_messages,
        luncher_position: assistant.luncher_position,
        luncher_bg_color: assistant.luncher_config?.bg_color ?? '#000',
        contact_message_color:
            assistant.config?.contact_message_color ?? '#000',
    })

    console.log(assistant)

    const [chatAvatar, setChatAvatar] = useState<File | null>(null)
    const [luncherIcon, setLuncherIcon] = useState<File | null>(null)

    const { mutate, isLoading } = useMutation({
        mutationKey: ['update-assistant'],
        mutationFn: updateAssistant,
    })

    const avatarUpdateMutation = useMutation({
        mutationKey: ['update-assistant-icons'],
        mutationFn: updateAssistantIcons,
    })

    const onValueChange = (key: any, value: any) => {
        setFormData({
            ...formData,
            [key]: value,
        })
    }

    const handleFileUpload = (callbacks: any) => {
        const formData = new FormData()

        formData.append('_method', 'PUT')

        if (chatAvatar) {
            formData.append('logo_file', chatAvatar)
        }
        if (luncherIcon) {
            formData.append('luncher_icon_file', luncherIcon)
        }

        return avatarUpdateMutation.mutate(
            {
                projectId: assistant.project_id,
                data: formData,
            },
            callbacks
        )
    }

    const handleSubmit = () => {
        mutate(
            {
                projectId: assistant.project_id,
                data: formData,
            },
            {
                onSuccess: () => {
                    handleFileUpload({
                        onSuccess: () => {
                            notifySuccess('Chatbot updated successfully.')
                        },
                        onError: (error: any) => {
                            console.error(error)
                            notifyError('Something went wrong.')
                        },
                    })
                },
                onError: (error: any) => {
                    console.error(error)
                    notifyError('Something went wrong.')
                },
            }
        )
    }

    return (
        <div className="space-y-4">
            {/* Display Name */}
            <Card>
                <CardContent className="px-4 pb-4 pt-2">
                    <Label htmlFor="initial-msg" className="mb-2 block text-sm">
                        Display Name
                    </Label>
                    <Input
                        className="text-slate-500"
                        id="display-name"
                        placeholder="Display Name"
                        defaultValue={formData.name}
                        onChange={(e) => {
                            onValueChange('name', e.target.value)
                        }}
                    />
                </CardContent>
            </Card>

            {/* Initial Messages */}
            <Card>
                <CardContent className="px-4 pb-4 pt-2">
                    <Label htmlFor="initial-msg" className="mb-2 block text-sm">
                        Initial Messages
                    </Label>
                    <Textarea
                        className="mb-4 text-slate-500"
                        id="initial-msg"
                        placeholder="Type your messages here."
                        defaultValue={formData.initial_messages?.join('\n')}
                        onChange={(e) => {
                            onValueChange(
                                'initial_messages',
                                e.target.value.split('\n')
                            )
                        }}
                    />

                    <p className="text-xs text-slate-500">
                        Enter each message in a new line.
                    </p>
                </CardContent>
            </Card>

            {/* Suggested Messages */}
            <Card>
                <CardContent className="px-4 pb-4 pt-2">
                    <Label
                        className="mb-2 block text-sm"
                        htmlFor="suggested-msg"
                    >
                        Suggested Messages
                    </Label>
                    <Textarea
                        className="mb-4 text-slate-500"
                        id="suggested-msg"
                        placeholder="Type your message here."
                        defaultValue={formData.suggested_messages?.join('\n')}
                        onChange={(e) => {
                            onValueChange(
                                'suggested_messages',
                                e.target.value.split('\n')
                            )
                        }}
                    />
                    <p className="text-xs text-slate-500">
                        Enter each message in a new line.
                    </p>
                </CardContent>
            </Card>

            {/* Chatbot Avatar */}
            <Card>
                <CardContent className="px-4 pb-4 pt-2">
                    <Label
                        htmlFor="chatbot-avatar"
                        className="mb-2 block text-sm"
                    >
                        Chatbot Avatar
                    </Label>

                    <div className="flex justify-between">
                        <div className="w-1/2">
                            <Input
                                id="chatbot-avatar"
                                type="file"
                                accept="image/*"
                                onChange={(e: any) => {
                                    setChatAvatar(e.target.files[0])
                                }}
                            />
                        </div>

                        <div className="overflow-hidden">
                            {chatAvatar ? (
                                <>
                                    <img
                                        src={URL.createObjectURL(chatAvatar)}
                                        alt="avatar"
                                        className="h-10 w-10 rounded-full"
                                    />
                                </>
                            ) : (
                                <>
                                    {assistant?.logo_url ? (
                                        <img
                                            src={assistant?.logo_url}
                                            alt="avatar"
                                            className="h-10 w-10 rounded-full"
                                            style={{
                                                background:
                                                    formData.luncher_bg_color,
                                            }}
                                        />
                                    ) : (
                                        <DefaultIcon
                                            style={{
                                                background:
                                                    formData.luncher_bg_color,
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Chat Bubble Color */}
            <Card>
                <CardContent className="px-4 pb-4 pt-2">
                    <Label className="mb-2 block text-sm">
                        Chat Bubble Color
                    </Label>
                    <div className="mb-3.5 flex items-center space-x-3">
                        <Input
                            type="text"
                            className="w-1/3"
                            value={formData.luncher_bg_color}
                            onChange={(e) => {
                                onValueChange(
                                    'luncher_bg_color',
                                    e.target.value
                                )
                            }}
                        />

                        <input
                            type="color"
                            value={formData.luncher_bg_color}
                            prefix="#"
                            onChange={(e) => {
                                onValueChange(
                                    'luncher_bg_color',
                                    e.target.value
                                )
                            }}
                            className="color-input h-10 w-10 rounded-full border border-solid border-gray-200"
                        />
                    </div>
                </CardContent>
            </Card>

            {/* Chat bubble Icon */}
            <Card>
                <CardContent className="px-4 pb-4 pt-2">
                    <Label
                        htmlFor="chatbot-bubble-icon"
                        className="mb-2 block text-sm"
                    >
                        Chat Bubble Icon
                    </Label>

                    <div className="flex justify-between">
                        <div className="w-1/2">
                            <Input
                                id="chatbot-bubble-icon"
                                type="file"
                                accept="image/*"
                                onChange={(e: any) => {
                                    setLuncherIcon(e.target.files[0])
                                }}
                            />
                        </div>

                        <div className="overflow-hidden">
                            {luncherIcon ? (
                                <>
                                    <img
                                        src={URL.createObjectURL(luncherIcon)}
                                        alt="avatar"
                                        className="h-10 w-10 rounded-full"
                                    />
                                </>
                            ) : (
                                <>
                                    {assistant?.luncher_icon_url ? (
                                        <img
                                            src={assistant?.luncher_icon_url}
                                            alt="avatar"
                                            className="h-10 w-10 rounded-full"
                                            style={{
                                                background:
                                                    formData.luncher_bg_color,
                                            }}
                                        />
                                    ) : (
                                        <DefaultIcon
                                            style={{
                                                background:
                                                    formData.luncher_bg_color,
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <input
                        className="absolute opacity-0"
                        type="file"
                        id="profile"
                    />
                </CardContent>
            </Card>

            {/* Chat bubble position */}
            <Card>
                <CardContent className="px-4 pb-4 pt-2">
                    <Label className="mb-2 block text-sm">
                        Align Chat Bubble Button
                    </Label>

                    <div className="w-1/2">
                        <Select defaultValue={formData.luncher_position}>
                            <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select position" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="bottom-right">
                                    Bottom-Right
                                </SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </CardContent>
            </Card>

            <div className="flex justify-end">
                <Button
                    className="px-6 py-[7px] font-normal text-slate-25"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}
