import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
} from '@/components/ui/select'
import { Slider } from '@/components/ui/slider'
import { Textarea } from '@/components/ui/textarea'
import { updateChatbot } from '@/services/projects'
import { projectStore } from '@/stores/projects.store'
import { Chatbot } from '@/types'
import { notifyError, notifySuccess } from '@/utils/toast'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

type Props = {
    chatbot: Chatbot
}

export const AiTabContent = ({ chatbot }: Props) => {
    const currentProject = projectStore((state: any) => state.currentProject)

    const [formData, setFormData] = useState({
        llm_prompt: chatbot.llm_prompt,
        llm_prompt_restriction: chatbot.llm_prompt_restriction,
        llm_model: chatbot.llm_model,
        llm_temperature: chatbot.llm_temperature,
    })

    const { mutate, isLoading } = useMutation({
        mutationKey: ['update-chatbot'],
        mutationFn: updateChatbot,
    })

    const onValueChange = (key: any, value: any) => {
        setFormData({
            ...formData,
            [key]: value,
        })
    }

    const handleSubmit = () => {
        console.log(formData)

        mutate(
            {
                projectId: currentProject.id,
                data: formData,
            },
            {
                onSuccess: (_) => {
                    notifySuccess('Chatbot updated successfully.')
                },
                onError: (_) => {
                    notifyError('Something went wrong. Please try again later.')
                },
            }
        )
    }

    const aiModels = [
        {
            label: 'GPT-4o',
            value: 'gpt-4o',
        },
        {
            label: 'GPT-4 Turbo',
            value: 'gpt-4-turbo',
        },
        {
            label: 'GPT-4',
            value: 'gpt-4',
        },
        {
            label: 'GPT-3.5 Turbo',
            value: 'gpt-3.5-turbo',
        },
    ]

    return (
        <>
            <Card className="mb-4">
                <>
                    <CardContent className="p-3">
                        <p className="mb-2 font-medium text-slate-900">
                            Base Prompt (Instructions)
                        </p>
                        <Textarea
                            className="mb-3 min-h-[200px] pb-7 leading-[1.57] text-slate-500"
                            placeholder="Write the basic prompt here."
                            defaultValue={formData.llm_prompt}
                            onChange={(event) =>
                                onValueChange('llm_prompt', event.target.value)
                            }
                        />
                        <p className="text-xs text-slate-500">
                            The basic prompt lets you tweak your chatbot's
                            personality and style. Try different things with the
                            basic prompt to match your data and how you want to
                            use it.
                        </p>
                    </CardContent>

                    <CardContent className="p-3">
                        <p className="mb-2 font-medium text-slate-900">
                            Restriction
                        </p>
                        <Textarea
                            className="mb-3 min-h-[200px] pb-7 leading-[1.57] text-slate-500"
                            placeholder="Write the restriction prompt here."
                            defaultValue={formData.llm_prompt_restriction}
                            onChange={(event) =>
                                onValueChange(
                                    'llm_prompt_restriction',
                                    event.target.value
                                )
                            }
                        />
                        <p className="text-xs text-slate-500">
                            The Restriction prompt helps you set the chatbot's
                            response boundaries and improve response quality.
                        </p>
                    </CardContent>
                </>
            </Card>
            <Card className="mb-4">
                <CardContent className="p-3">
                    <p className="mb-2 font-medium text-slate-900">Model</p>
                    <Select
                        defaultValue={formData.llm_model}
                        onValueChange={(value) =>
                            onValueChange('llm_model', value)
                        }
                    >
                        <SelectTrigger className="mb-3">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            {aiModels.map((model) => (
                                <SelectItem
                                    key={model.value}
                                    value={model.value}
                                >
                                    {model.label}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    <p className="text-xs text-slate-500">
                        gpt-4 is much better at following the base prompt and
                        not hallucinating, but it's slower and more expensive
                        than gpt-3.5-turbo (1 message using gpt-3.5-turbo costs
                        1 message credit. 1 message using gpt-4 costs 20 message
                        credits)
                    </p>
                </CardContent>
            </Card>
            <Card className="mb-4">
                <CardContent className="p-3">
                    <p className="mb-3.5 font-medium text-slate-900">
                        Temperature
                    </p>
                    <Slider
                        className="mb-1.5"
                        defaultValue={[formData.llm_temperature]}
                        onValueChange={(value) =>
                            onValueChange('llm_temperature', value[0])
                        }
                        max={1}
                        step={0.1}
                    />
                    <div className="mb-3 flex items-center justify-between">
                        <span className="text-xs text-slate-500">Reserved</span>
                        <span className="text-xs text-slate-500">Creative</span>
                    </div>
                </CardContent>
            </Card>

            <div className="flex justify-end py-3">
                <Button
                    className="px-6 py-[7px] font-normal text-slate-25"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    Save
                </Button>
            </div>
        </>
    )
}
