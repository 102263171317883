import Axios from 'axios'

const baseURL = import.meta.env.VITE_API_BASE_URL

const axios = Axios.create({
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    baseURL: baseURL
})

export const csrf = () => axios.get(baseURL + '/sanctum/csrf-cookie')

export default axios
