import { PiUploadSimple } from 'react-icons/pi'
import { Button } from '../ui/button'

type Props = {
    inputKey: string
    onChange: (files: FileList | null) => void
    disabled?: boolean
    multiple?: boolean
}

export function FileSelect({
    inputKey,
    onChange,
    disabled = false,
    multiple = false,
}: Props) {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.files)
    }

    return (
        <fieldset className="form-group">
            <input
                key={inputKey}
                className="hidden"
                type="file"
                id="files"
                accept=".pdf, .docx, .txt"
                onChange={handleOnChange}
                disabled={disabled}
                multiple={multiple}
            />

            <label htmlFor="files">
                <div className="cursor-pointer rounded-lg border border-dashed border-slate-300 bg-white px-6 py-8 text-center hover:bg-gray-50">
                    <div className="mx-auto mb-3 flex h-11 w-11 items-center justify-center rounded-full bg-slate-50">
                        <PiUploadSimple className="text-xl" />
                    </div>
                    <p>Drag & drop files here, or click to select files</p>

                    <p className="mt-1 text-xs text-gray-500">
                        Supported File Types: .pdf, .docx, .txt
                    </p>
                    <div className="mt-8">
                        <Button
                            asChild
                            className="shadow-none"
                            variant="secondary"
                        >
                            <p>Select File</p>
                        </Button>
                    </div>
                </div>
            </label>
        </fieldset>
    )
}
