import { projectStore, setEmbeddingStatus } from '@/stores/projects.store'
import { cn } from '@/utils'
import { PropsWithChildren } from 'react'
import { Link, useLocation } from 'react-router-dom'
import DocumentsOverview from './documents-overview'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { processEmbedding } from '@/services/embedding'
import { useMutation } from '@tanstack/react-query'
import { LoaderButton } from '@/components/ui/loader-button'

export function Layout({ children }: Readonly<PropsWithChildren>) {
    const currentProject = projectStore((state: any) => state.currentProject)

    const { pathname } = useLocation()

    const navLinks = [
        {
            label: 'Files',
            href: `/p/${currentProject?.id}/data/files`,
            active: pathname.endsWith(`files`),
        },
        {
            label: 'Websites',
            href: `/p/${currentProject?.id}/data`,
            active: pathname.endsWith(`data`),
        },
        {
            label: 'Texts',
            href: `/p/${currentProject?.id}/data/texts`,
            active: pathname.endsWith(`texts`),
        },
        {
            label: 'Q&A',
            href: `/p/${currentProject?.id}/data/qna`,
            active: pathname.endsWith(`qna`),
        },
    ]

    const processEmbeddingMutation = useMutation({
        mutationKey: ['process-embedding'],
        mutationFn: processEmbedding,
    })

    const handleUpdate = () => {
        processEmbeddingMutation.mutate(currentProject?.embedding.id, {
            onSuccess: () => {
                // FIXME
                setEmbeddingStatus('Embedding')
            },
        })
    }

    return (
        <div className="flex min-h-[500px] justify-between space-x-6">
            <div className="w-[180px] text-sm">
                <nav>
                    <ul className="space-y-3">
                        {navLinks.map(({ label, href, active }) => (
                            <li key={href}>
                                <Link
                                    to={href}
                                    className={cn('text-slate-500', {
                                        'text-slate-900': active,
                                    })}
                                >
                                    {label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className="flex-1 border-l pl-6">
                <div className="mb-6 space-y-4">
                    {(currentProject?.embedding?.status === 'Pending' ||
                        currentProject?.embedding?.status === 'Failed') && (
                        <Alert
                            className="animate-border flex items-center justify-between rounded-xl bg-yellow-50 p-5 text-orange-400"
                            variant="warning"
                        >
                            <div>
                                <AlertTitle>Update Required</AlertTitle>
                                <AlertDescription>
                                    You have updates the documents, you must
                                    have to update embeddings to get the latest
                                    results.
                                </AlertDescription>
                            </div>
                            <div>
                                <LoaderButton
                                    onClick={handleUpdate}
                                    isLoading={
                                        processEmbeddingMutation.isLoading
                                    }
                                    variant="ghost"
                                    className="relative rounded-lg bg-orange-400 text-white hover:bg-yellow-600 hover:text-white"
                                >
                                    Update Now
                                </LoaderButton>
                            </div>
                        </Alert>
                    )}

                    <DocumentsOverview />
                </div>

                {children}
            </div>
        </div>
    )
}
