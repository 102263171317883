import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { getAssistant } from '@/services/projects'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { AiTabContent } from './components/ai'
import { EmbedTabContent } from './components/embed'
import { AppearanceTabContent } from './components/appearance'
import { SecurityContent } from './components/security'
import { Loading } from '@/components/shared/full-page-loading'
import { Assistant, Chatbot } from '@/types'
import { ProjectSettingsPage } from './components/settings'
import { ProjectDeletePage } from './components/delete'

export function ProjectChatbotPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const { projectId } = useParams<{ projectId: string }>()
    const [chatbot, setChatbot] = useState<Chatbot>()
    const [assistant, setAssistant] = useState<Assistant>()
    const [activeTab, setActiveTab] = useState<string>(
        searchParams.get('tab') || 'general'
    )

    const handleTabChange = (value: string) => {
        setActiveTab(value)
        searchParams.set('tab', value)
        setSearchParams(searchParams)
    }

    const { isLoading, isError } = useQuery({
        queryKey: [`project-${projectId}-chatbot`],
        queryFn: () => getAssistant(projectId as string),
        onSuccess: (data) => {
            setAssistant(data?.data.assistant)
            setChatbot(data?.data.chatbot)
        },
    })

    return (
        <>
            {isLoading && <Loading />}
            {isError && (
                <div className="text-center text-lg font-medium text-slate-700">
                    Something went wrong. Please try again later.
                </div>
            )}

            {!isLoading && !isError && chatbot && assistant && (
                <Tabs defaultValue={activeTab} onValueChange={handleTabChange}>
                    <div className="flex min-h-[500px] justify-between space-x-6">
                        <div className="w-[180px] text-sm">
                            <TabsList className="flex flex-col items-start space-x-0 space-y-3 border-0">
                                <TabsTrigger
                                    value="general"
                                    className="border-b-0 py-0"
                                >
                                    General
                                </TabsTrigger>
                                <TabsTrigger
                                    value="ai"
                                    className="border-b-0 py-0"
                                >
                                    Ai Model
                                </TabsTrigger>
                                <TabsTrigger
                                    value="chat-appearance"
                                    className="border-b-0 py-0"
                                >
                                    Appearance
                                </TabsTrigger>
                                <TabsTrigger
                                    value="security"
                                    className="border-b-0 py-0"
                                >
                                    Security
                                </TabsTrigger>
                                <TabsTrigger
                                    value="embed"
                                    className="border-b-0 py-0"
                                >
                                    Embed on Site
                                </TabsTrigger>
                                <TabsTrigger
                                    value="danger"
                                    className="border-b-0 py-0 text-red-400 data-[state=active]:text-destructive"
                                >
                                    Danger
                                </TabsTrigger>
                            </TabsList>
                        </div>
                        <div className="flex-1 border-l pl-6">
                            <TabsContent value="general" className="mt-0">
                                <ProjectSettingsPage />
                            </TabsContent>
                            <TabsContent value="ai" className="mt-0">
                                <AiTabContent chatbot={chatbot} />
                            </TabsContent>
                            <TabsContent
                                value="chat-appearance"
                                className="mt-0"
                            >
                                <AppearanceTabContent assistant={assistant} />
                            </TabsContent>
                            <TabsContent value="embed" className="mt-0">
                                <EmbedTabContent assistant={assistant} />
                            </TabsContent>
                            <TabsContent value="security" className="mt-0">
                                <SecurityContent assistant={assistant} />
                            </TabsContent>
                            <TabsContent value="danger" className="mt-0">
                                <ProjectDeletePage />
                            </TabsContent>
                        </div>
                    </div>
                </Tabs>
            )}
        </>
    )
}
