import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { deleteProject } from '@/services/projects'
import { projectStore } from '@/stores/projects.store'
import { notifySuccess, notifyError } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Input } from '@/components/ui/input-field'
import { useNavigate } from 'react-router-dom'

export function ProjectDeletePage() {
    const currentProject = projectStore((state: any) => state.currentProject)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteConfirmation, setDeleteConfirmation] = useState('')
    const navigate = useNavigate()

    const deleteMutation = useMutation({
        mutationKey: ['deleteProject'],
        mutationFn: deleteProject,
    })

    const handleDelete = () => {
        if (deleteConfirmation !== 'delete') {
            notifyError('Please type "delete" to confirm.')
            return
        }

        deleteMutation.mutate(currentProject.id, {
            onSuccess: () => {
                notifySuccess('Project deleted successfully.')
                navigate('/dashboard')
            },
            onError: (error) => {
                console.error(error)
                notifyError('Something went wrong. Please try again later.')
            },
        })

        setShowDeleteModal(false)
    }

    return (
        <>
            <section>
                <div className="max-w-3xl">
                    <h3 className="mb-3 text-lg font-medium">Danger Zone</h3>

                    <Card className="border-red-300 shadow-none">
                        <CardContent className="flex items-center justify-between py-4">
                            <div>
                                <p className="mb-1">Delete this project</p>
                                <p className="text-sm">
                                    Once you delete a project, there is no going
                                    back. Please be certain.
                                </p>
                            </div>

                            <div>
                                <Button
                                    variant="destructive"
                                    onClick={() => setShowDeleteModal(true)}
                                >
                                    Delete Project
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </section>

            <AlertDialog
                open={showDeleteModal}
                onOpenChange={(state) => setShowDeleteModal(state)}
            >
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. This will permanently
                            delete this project, chatbot and remove all your
                            data related to this project from our servers.
                        </AlertDialogDescription>

                        <AlertDialogDescription className="pt-6">
                            Please type 'delete' to confirm.
                        </AlertDialogDescription>

                        <Input
                            className="border-red-300"
                            onChange={(e) =>
                                setDeleteConfirmation(e.target.value)
                            }
                        />
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <Button
                            onClick={handleDelete}
                            variant="destructive"
                            disabled={deleteConfirmation !== 'delete'}
                        >
                            Confirm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}
