import { Button } from '@/components/ui/button'
import { Layout } from './components/data-layout'
import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchUrl as fetchUrlFn } from '@/services/scrapper'
import * as Yup from 'yup'
import { getDocuments } from '@/services/embedding'
import { Input } from '@/components/ui/input-field'
import { Document } from '../../../../components/shared/document'
import { createDocument, deleteDocument } from '@/services/documents'
import { notifyError, notifySuccess } from '@/utils/toast'
import { nanoid } from '@/utils'
import { projectStore, setEmbeddingStatus } from '@/stores/projects.store'
import { LoaderButton } from '@/components/ui/loader-button'
import { useConfig } from '@/hooks/config'

const LoadingProgress = ({
    maxIntervalSeconds = 30,
    loadingText = 'Loading...',
}) => {
    const [progress, setProgress] = useState<number>(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    return 100
                }

                return prev + 100 / maxIntervalSeconds
            })
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className="mx-auto w-full max-w-xl text-center">
            <div className="relative h-3 w-full overflow-hidden rounded-full border border-gray-300 bg-transparent text-xs">
                <div
                    className="absolute h-full bg-gray-800/95 transition-all duration-500"
                    style={{ width: `${progress}%` }}
                ></div>
            </div>

            <div className="flex w-full items-center justify-between text-sm">
                <p className="mt-2 text-slate-500">{loadingText}</p>
                <p>{Math.floor(progress)}%</p>
            </div>
        </div>
    )
}

export function ProjectDataPage() {
    const { scrapping } = useConfig()
    const currentProject = projectStore((state: any) => state.currentProject)
    const [fetchUrl, setFetchUrl] = useState<string>('')
    const [documents, setDocuments] = useState<Array<any>>([])
    const [links, setLinks] = useState<any[]>([])

    const controllerRef = useRef(new AbortController())

    const embeddingId = currentProject?.embedding.id

    const { isLoading } = useQuery({
        queryKey: [`embedding-${embeddingId}-data-website-urls`],
        queryFn: () => getDocuments(embeddingId as string, 'url'),
        onSuccess: (data) => setDocuments(data?.data),
    })

    const { mutate, isLoading: isFetchingUrl } = useMutation({
        mutationKey: ['fetchUrl'],
        mutationFn: () =>
            fetchUrlFn({ url: fetchUrl, signal: controllerRef.current.signal }),
        onSuccess: (data) => {
            const _links = data?.data.map((link: any) => {
                return {
                    id: nanoid(),
                    title: link.url,
                    type: 'URL',
                    meta: {},
                    content: link.content,
                    char_count: link.content?.length || 0,
                }
            })

            setLinks(_links)
        },
        onError: (error) => {
            console.error(error)

            notifyError(
                (error as Error)?.name === 'CanceledError'
                    ? 'Request was canceled.'
                    : 'Something went wrong. Please try again later.'
            )
        },
    })

    const createMutation = useMutation({
        mutationFn: createDocument,
    })

    const deleteMutation = useMutation({
        mutationKey: ['delete-document'],
        mutationFn: deleteDocument,
    })

    const handleLinkFetch = async () => {
        Yup.string()
            .url()
            .required()
            .validate(fetchUrl)
            .then((_) => {
                mutate()
            })
            .catch((err) => {
                notifyError(err.message)
            })
    }

    const removeLink = (id: string) => {
        setLinks((prev) => prev.filter((link) => link !== id))
    }

    const handleUrlDelete = (documentId: string) => {
        // setDocuments((prev) => prev.filter((doc) => doc?.id !== id))

        if (!confirm('Are you sure you want to delete this file?')) {
            return
        }

        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                setDocuments((prev) =>
                    prev.filter((document: any) => document?.id !== documentId)
                )

                notifySuccess('File is being deleted')
            },
            onError: (error) => {
                console.error(error)
                notifyError('Something went wrong. Please try again later.')
            },
        })
    }

    const handleUrlRefetch = (id: string) => {
        // TODO
        console.log('Refetching', id)
    }

    const handleLinksSave = () => {
        createMutation.mutate(
            { embeddingId, type: 'url', document: { urls: links } },
            {
                onSuccess: (data) => {
                    setDocuments((prev) => [...prev, ...data.data])
                    setLinks([])

                    // FIXME
                    setEmbeddingStatus('Pending')

                    notifySuccess('File created successfully')
                },
                onError: (error) => {
                    console.error(error)
                    notifyError('Something went wrong. Please try again later.')
                },
            }
        )
    }

    return (
        <Layout>
            {/* Crawl */}
            <fieldset className="form-group">
                <label
                    className="mb-2 inline-block text-slate-900"
                    htmlFor="fetch-url-input"
                >
                    Crawl
                </label>
                <div className="flex items-center justify-between space-x-3">
                    <Input
                        id="fetch-url-input"
                        value={fetchUrl}
                        onChange={(e: any) => setFetchUrl(e.target.value)}
                        className="placeholder:text-slate-300"
                        placeholder="https://chatever.ai"
                    />

                    <div>
                        <LoaderButton
                            onClick={handleLinkFetch}
                            className="py-5"
                            isLoading={isFetchingUrl}
                        >
                            {isFetchingUrl ? 'Fetching...' : 'Fetch Links'}
                        </LoaderButton>
                    </div>
                </div>
                <p className="my-2 text-sm text-slate-500">
                    This will crawl all the links starting with the URL (not
                    including files on the website).
                </p>
            </fieldset>

            {/* <div className="my-4 font-medium capitalize text-primary">or</div>

            <fieldset className="form-group">
                <label
                    className="mb-2 inline-block text-slate-900"
                    htmlFor="sitemap"
                >
                    Submit Sitemap
                </label>
                <div className="flex items-center justify-between space-x-3">
                    <Input
                        className="placeholder:text-slate-300"
                        id="sitemap"
                        placeholder="https://www.example.com/sitemap.xml"
                    />
                    <Button className="whitespace-nowrap">Load sitemap</Button>
                </div>
                <p className="my-2 text-sm text-slate-500">
                    This will crawl all the links starting with the URL (not
                    including files on the website).
                </p>
            </fieldset> */}

            {/* Fetched links */}
            {isFetchingUrl ? (
                <div className="py-12 text-center">
                    <LoadingProgress
                        maxIntervalSeconds={scrapping.maxProcessTime}
                    />

                    <Button
                        variant="ghost"
                        className="text-red-500 hover:text-red-600"
                        onClick={() => {
                            controllerRef.current.abort()
                            controllerRef.current = new AbortController() // Reset the controller
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            ) : (
                links.length > 0 && (
                    <div className="mt-[30px]">
                        <div className="mb-1 flex items-center justify-between">
                            <p className="mb-1 block font-medium capitalize text-primary">
                                Fetched Links
                            </p>
                        </div>
                        <div className="mb-6 w-full space-y-2">
                            {links?.map((document: any) => (
                                <Document
                                    key={document?.id}
                                    document={document}
                                    onDelete={removeLink}
                                />
                            ))}
                        </div>

                        <div>
                            <Button onClick={handleLinksSave} className="py-5">
                                Save Links
                            </Button>
                        </div>
                    </div>
                )
            )}

            {/* Include links */}
            {!isLoading && documents.length !== 0 && (
                <div className="mt-[30px]">
                    <div className="mb-1 flex items-center justify-between">
                        <p className="mb-1 block font-medium capitalize text-primary">
                            Included Links
                        </p>
                    </div>
                    <div className="mb-6 w-full space-y-2">
                        {documents?.map((document: any) => (
                            <Document
                                key={document?.id}
                                document={document}
                                onReload={handleUrlRefetch}
                                onDelete={handleUrlDelete}
                            />
                        ))}
                    </div>
                </div>
            )}
        </Layout>
    )
}
