type Props = {
    className?: string
}

export default function ({ className = 'h-8 w-8' }: Props) {
    return (
        <svg
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0 70.3125C0 31.48 31.48 0 70.3125 0H129.688C168.52 0 200 31.48 200 70.3125V129.688C200 168.52 168.52 200 129.688 200H70.3125C31.48 200 0 168.52 0 129.688V70.3125Z"
                fill="#181815"
            />
            <path
                d="M124.491 50.7812C124.491 46.4665 120.993 42.9688 116.678 42.9688H65.9467C51.099 42.9688 39.0625 55.0219 39.0625 69.8902V118.264C39.0625 133.133 51.099 145.186 65.9467 145.186H115.403C133.109 145.186 149.68 146.102 159.71 160.713L160.938 162.5V79.2969C160.938 74.9822 157.44 71.4844 153.125 71.4844H144.022C133.235 71.4844 124.491 62.7399 124.491 51.9531V50.7812Z"
                fill="white"
            />
            <path
                d="M148.422 30.95C148.912 38.6813 155.091 44.843 162.844 45.3313C155.091 45.8195 148.912 51.9811 148.422 59.7125C147.933 51.9811 141.753 45.8195 134 45.3313C141.753 44.843 147.933 38.6813 148.422 30.95Z"
                fill="white"
            />
            <path
                d="M168.222 48.5C168.554 53.7416 172.743 57.919 178 58.25C172.743 58.581 168.554 62.7584 168.222 68C167.89 62.7584 163.701 58.581 158.444 58.25C163.701 57.919 167.89 53.7416 168.222 48.5Z"
                fill="white"
            />
            <path
                d="M169.933 29C170.174 32.8001 173.211 35.8288 177.022 36.0688C173.211 36.3087 170.174 39.3373 169.933 43.1375C169.693 39.3373 166.655 36.3087 162.844 36.0688C166.655 35.8288 169.693 32.8001 169.933 29Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M118.748 110.41C124.686 106.993 129.617 102.079 133.046 96.1604L133.635 95.1434L125.041 90.1985L124.455 91.2095C121.898 95.6235 118.22 99.2893 113.79 101.838C109.36 104.387 104.335 105.729 99.2194 105.729C94.1036 105.729 89.0783 104.387 84.6484 101.838C80.2185 99.2893 76.5404 95.6235 73.9834 91.2095L73.3977 90.1985L64.8037 95.1434L65.3929 96.1604C68.8216 102.079 73.7528 106.993 79.6906 110.41C85.6284 113.827 92.3636 115.625 99.2194 115.625C106.075 115.625 112.81 113.827 118.748 110.41Z"
                fill="#181815"
            />
        </svg>
    )
}
