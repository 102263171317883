import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input-field'
import { teamStore } from '@/stores/teams.store'

export function TeamSettingPage() {
    const team = teamStore((state: any) => state.currentTeam)

    return (
        <section className="grid grid-cols-6 gap-6">
            <div className="col-span-4">
                <form className='space-y-6'>
                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Team Name
                        </label>
                        <div className="mt-2">
                            <Input
                                id="name"
                                type="name"
                                defaultValue={team?.name}
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <Button>Save</Button>
                    </div>
                </form>
            </div>
        </section>
    )
}
