import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { projectStore } from '@/stores/projects.store'
import { copyToClipboard, notifyError, notifySuccess } from '@/utils'
import { PiCopy } from 'react-icons/pi'

export const EmbedTabContent = ({ assistant }: any) => {
    const currentProject = projectStore((state: any) => state.currentProject)

    if (!currentProject) return null

    const handleCopy = (divId: string) => {
        try {
            const copyText = document.getElementById(divId) as HTMLInputElement

            copyToClipboard(copyText.innerText)

            notifySuccess('Copied to clipboard')
        } catch (error) {
            notifyError('Failed to copy')
        }
    }

    const iframeText = `<iframe src="${import.meta.env.VITE_CHATBOT_BASE_URL}/${
        assistant.id
    }" width="100%" style="height: 100%; min-height: 700px" frameborder="0"></iframe>`

    const scriptText = `<script>
    window.chatever = {
        assistantId: '${assistant.id}',
    }
</script>
<script src="${import.meta.env.VITE_CHATBOT_ASSISTANT_SCRIPT_URL}" id="${
        assistant.id
    }" defer></script>`

    return (
        <Card>
            <CardContent className="py-4">
                <h3 className="mb-2 font-medium text-slate-900">
                    Embed the chatbot on your website
                </h3>

                <div className="space-y-8">
                    <div className="space-y-4">
                        <div className="rounded-md bg-slate-600 px-6 py-4 text-slate-200 md:px-4">
                            <pre className="whitespace-pre-line text-sm">
                                {import.meta.env.VITE_CHATBOT_BASE_URL}/
                                {assistant.id}
                            </pre>
                        </div>

                        <div className="flex items-center justify-between">
                            <p className="text-xs text-slate-500">
                                To visit the chatbot page, click the link below.
                            </p>

                            <a
                                className="space-x-2 font-normal"
                                href={`${
                                    import.meta.env.VITE_CHATBOT_BASE_URL
                                }/${assistant.id}`}
                                target="_blank"
                            >
                                <span>Visit</span>
                            </a>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div className="rounded-md bg-slate-600 px-6 py-4 text-slate-200 md:px-4">
                            <pre
                                className="whitespace-pre-line text-sm"
                                id="iframeContent"
                            >
                                {iframeText}
                            </pre>
                        </div>

                        <div className="flex items-center justify-between">
                            <p className="text-xs text-slate-500">
                                To add the chatbot any where on your website,
                                add this iframe to your html code.
                            </p>

                            <Button
                                className="space-x-2 font-normal"
                                onClick={() => handleCopy('iframeContent')}
                            >
                                <PiCopy className="h-4 w-4" />
                                <span>Copy</span>
                            </Button>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div className="rounded-md bg-slate-600 px-6 py-4 text-slate-200 md:px-4">
                            <pre
                                className="whitespace-pre-line text-sm"
                                id="embedScriptContent"
                            >
                                {scriptText}
                            </pre>
                        </div>
                        <div className="flex items-center justify-between">
                            <p className="text-xs text-slate-500">
                                To add a chat bubble to your website add this
                                script tag to your html.
                            </p>

                            <Button
                                className="space-x-2 font-normal"
                                onClick={() => handleCopy('embedScriptContent')}
                            >
                                <PiCopy className="h-4 w-4" />
                                <span>Copy</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
