import { useAuth } from '@/hooks/auth'
import { useState } from 'react'
import { SessionStatus } from './components/session-status'
import { LoaderButton } from '@/components/ui/loader-button'
import { Button } from '@/components/ui/button'

export default function VerifyEmail() {
    const { logout, resendEmailVerification } = useAuth({
        middleware: 'auth',
        redirectIfAuthenticated: '/dashboard',
    })

    const [status, setStatus] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const handleResendEmailVerification = () => {
        setLoading(true)
        resendEmailVerification({
            setStatus,
            setErrors: () => {},
        })
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }

    return (
        <div className="relative flex min-h-[calc(100vh-80px)] flex-col items-center justify-center overflow-hidden">
            <div className="m-auto w-full lg:max-w-2xl">
                <div className="mx-2">
                    <div className="mb-4 text-sm text-gray-600">
                        Thanks for signing up! Before getting started, could you
                        verify your email address by clicking on the link we
                        just emailed to you? If you didn't receive the email, we
                        will gladly send you another.
                    </div>

                    {status === 'verification-link-sent' && (
                        <SessionStatus
                            className="mb-4"
                            status="A new verification link has been sent to the email
                        address you provided during registration"
                        />
                    )}

                    <div className="mt-4 flex items-center justify-between">
                        <LoaderButton
                            onClick={handleResendEmailVerification}
                            className="select-none"
                            isLoading={loading}
                        >
                            Resend Verification Email
                        </LoaderButton>

                        <Button
                            type="button"
                            variant="ghost"
                            className="text-sm text-gray-600 underline hover:text-gray-900"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
