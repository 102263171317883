import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { createProject } from '@/services/projects'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { loadProjects } from '@/stores/projects.store'
import { uiDataStore } from '@/stores/ui-data.store'
import { notifyError, notifySuccess } from '@/utils/toast'
import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerFooter,
    DrawerClose,
} from '../ui/drawer'

const NewProjectDialogProvider = () => {
    const navigate = useNavigate()

    const showNewProjectDialog = uiDataStore(
        (state) => state.showNewProjectDialog
    )
    const setShowNewProjectDialog = uiDataStore(
        (state) => state.setShowNewProjectDialog
    )

    const [projectName, setProjectName] = useState<string>('')

    const { mutate, isLoading } = useMutation({
        mutationKey: ['createProject'],
        mutationFn: createProject,
    })

    const handleProjectCreate = async (event: any) => {
        event.preventDefault()

        mutate(
            {
                name: projectName,
            },
            {
                onSuccess: (data) => {
                    notifySuccess('Project created successfully.')

                    loadProjects()
                    setProjectName('')
                    navigate(`/p/${data?.data?.id}`)
                    setShowNewProjectDialog(false)
                },
                onError: (error) => {
                    console.error(error)
                    notifyError('Something went wrong. Please try again later.')
                },
            }
        )
    }

    return (
        <Drawer
            open={showNewProjectDialog}
            onOpenChange={setShowNewProjectDialog}
        >
            <DrawerContent>
                <DrawerHeader>
                    <form className="space-y-4">
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Project Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="name"
                                    type="name"
                                    value={projectName}
                                    onChange={(event) =>
                                        setProjectName(event.target.value)
                                    }
                                    required
                                    autoFocus
                                    className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={handleProjectCreate}
                                disabled={isLoading}
                                className=""
                            >
                                Create
                            </Button>
                        </div>
                    </form>
                </DrawerHeader>
                <DrawerFooter>
                    <DrawerClose asChild>
                        <Button variant="outline">Cancel</Button>
                    </DrawerClose>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export { NewProjectDialogProvider }
