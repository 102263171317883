import { Button } from '@/components/ui/button'
import { useAuth } from '@/hooks/auth'
import { teamStore } from '@/stores/teams.store'
import { cn } from '@/utils'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from '@radix-ui/react-popover'
import { useEffect, useState } from 'react'
import { PiCaretUpDown } from 'react-icons/pi'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

export function SettingLayout() {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const teams = teamStore((state: any) => state.teams)
    const currentTeam = teamStore((state: any) => state.currentTeam)
    const [isTeamSetting, setIsTeamSetting] = useState(false)

    const { user } = useAuth({ middleware: 'auth' })

    const profileNavigationLinks = [
        {
            label: 'General',
            path: '/settings',
            isActive: pathname.endsWith('/settings'),
        },
        {
            label: 'Security',
            path: '/settings/security',
            isActive: pathname.endsWith('/settings/security'),
        },
        {
            label: 'Teams',
            path: '/settings/teams',
            isActive: pathname.endsWith('/settings/teams'),
        },
    ]

    const teamNavigationLinks = [
        {
            label: 'General',
            path: '/settings/team/general',
            isActive: pathname.endsWith('/settings/team/general'),
        },
        {
            label: 'Team Members',
            path: '/settings/team/members',
            isActive: pathname.endsWith('/settings/team/members'),
        },
        {
            label: 'Integrations',
            path: '/settings/team/integrations',
            isActive: pathname.endsWith('/settings/team/integrations'),
        },
        {
            label: 'Billing',
            path: '/settings/team/billing',
            isActive: pathname.endsWith('/settings/team/billing'),
        },
    ]

    useEffect(() => {
        if (pathname.startsWith('/settings/team/')) {
            setIsTeamSetting(true)
        } else {
            setIsTeamSetting(false)
        }
    }, [pathname])

    return (
        <section className="container grid grid-cols-12 gap-6 py-10">
            <div className="col-span-3 border-r pl-6">
                <div className="mb-4">
                    <Popover>
                        <PopoverTrigger asChild>
                            <p className="mt-1 text-slate-500">
                                <button className="flex h-9 items-center space-x-3 rounded-3xl border border-slate-50 bg-slate-25 pr-1.5 text-left text-[14px] font-light text-slate-500 transition-all hover:bg-slate-50">
                                    <div className="flex items-center space-x-2">
                                        <div className="rounded-full border border-slate-100 bg-white">
                                            <img
                                                src={
                                                    currentTeam?.profile_photo_url
                                                }
                                                alt="team profile"
                                                className="h-8 w-8 rounded-full"
                                            />
                                        </div>
                                        <p className="flex items-center space-x-1.5">
                                            <span className="hidden sm:block">
                                                {currentTeam?.name}
                                            </span>
                                        </p>
                                    </div>
                                    <PiCaretUpDown className="h-5 w-5" />
                                </button>
                            </p>
                        </PopoverTrigger>
                        <PopoverContent
                            align="start"
                            alignOffset={1}
                            className="w-lg z-50 m-0 rounded-xl bg-white p-0 shadow-lg"
                        >
                            <div className="text-sm font-light text-slate-500">
                                <div className="space-y-4 p-2">
                                    <div className="space-y-0.5">
                                        <Button
                                            variant="ghost"
                                            className="flex w-full justify-start space-x-2 px-2 text-left font-light"
                                            onClick={() => {
                                                navigate('/settings')
                                            }}
                                        >
                                            <img
                                                src={user?.profile_photo_url}
                                                alt="profile"
                                                className="h-6 w-6 rounded-full border bg-white"
                                            />
                                            <span>Profile Setting</span>
                                        </Button>
                                        {teams?.map((team: any) => {
                                            return (
                                                <Button
                                                    key={team.id}
                                                    variant="ghost"
                                                    className="flex w-full justify-start space-x-2 px-2 text-left font-light"
                                                    onClick={() => {
                                                        navigate(
                                                            '/settings/team/general'
                                                        )
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            team?.profile_photo_url
                                                        }
                                                        alt="team profile"
                                                        className="h-6 w-6 rounded-full border bg-white"
                                                    />
                                                    <div>{team.name}</div>
                                                </Button>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>

                <nav>
                    <ul className="space-y-3 text-sm">
                        {isTeamSetting ? (
                            <>
                                {teamNavigationLinks.map(
                                    (link: any, index: any) => {
                                        return (
                                            <li key={index + link.path}>
                                                <Link
                                                    to={link.path}
                                                    className={cn(
                                                        'text-slate-500',
                                                        {
                                                            'text-slate-900':
                                                                link.isActive,
                                                        }
                                                    )}
                                                >
                                                    {link.label}
                                                </Link>
                                            </li>
                                        )
                                    }
                                )}
                            </>
                        ) : (
                            <>
                                {profileNavigationLinks.map(
                                    (link: any, index: any) => {
                                        return (
                                            <li key={index + link.path}>
                                                <Link
                                                    to={link.path}
                                                    className={cn(
                                                        'text-slate-500',
                                                        {
                                                            'text-slate-900':
                                                                link.isActive,
                                                        }
                                                    )}
                                                >
                                                    {link.label}
                                                </Link>
                                            </li>
                                        )
                                    }
                                )}
                            </>
                        )}
                    </ul>
                </nav>
            </div>
            <div className="col-span-9">
                <Outlet />
            </div>
        </section>
    )
}
