import { Button } from '@/components/ui/button'
import { cn } from '@/utils'
import {
    PiFilePdfDuotone,
    PiFileDoc,
    PiFileText,
    PiFile,
    PiTrash,
    PiRepeat,
    PiGlobe,
} from 'react-icons/pi'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
} from '@/components/ui/sheet'
import { useState } from 'react'
import { Textarea } from '@/components/ui/textarea'

const FileIcon = ({
    type,
    mime = 'application/pdf',
}: {
    type: string
    mime: string
}) => {
    if (type === 'URL') {
        return <PiGlobe className="h-5 w-5" />
    }

    const Icon =
        {
            'application/pdf': PiFilePdfDuotone,
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                PiFileDoc,
            'text/plain': PiFileText,
        }[mime] ?? PiFile

    return <Icon className="h-5 w-5" />
}

export function Document({ document, onDelete, onReload }: any) {
    const { type, title, meta, char_count, chunks, status } = document

    const [showChunks, setShowChunks] = useState<any>(false)

    const statusText = {
        Pending: 'Pending...',
        Failed: 'Failed...',
        Removing: 'Removing...',
        Embedding: 'Embedding...',
        Embedded: `(${char_count} chars)`,
    }

    const onClick = () => {
        setShowChunks(document)
    }

    return (
        <>
            <div
                key={document?.id}
                className={cn(
                    'group flex items-center justify-between space-x-2 text-sm',
                    {
                        'opacity-50': status === 'Removing',
                    }
                )}
                onClick={onClick}
            >
                <div
                    className={cn(
                        'relative flex flex-1 items-center justify-between space-x-2 rounded-md border border-gray-200 bg-slate-50/50 py-1.5 pl-2.5 pr-3',
                        {
                            'border-red-100 bg-red-50': status === 'Failed',
                            'border-yellow-200 bg-yellow-50':
                                status === 'Pending',
                            'border-blue-200/80 bg-blue-50':
                                status === 'Embedding',
                        }
                    )}
                >
                    <div className="flex items-center justify-between space-x-2 overflow-hidden">
                        <div className="rounded-full border border-slate-200 bg-white/60 p-1 text-gray-600">
                            <FileIcon
                                type={type}
                                mime={meta?.mime_type ?? ''}
                            />
                        </div>
                        <div className="w-full max-w-xl flex-1 overflow-hidden truncate text-ellipsis">
                            {title}
                        </div>

                        {/* <div className="absolute mr-2 left-0 px-1 opacity-0 text-xs transition-all bg-gray-100 group-hover:opacity-100">
                        {document?.summary}
                    </div> */}
                    </div>

                    <div className="flex-shrink-0 pl-1 text-gray-500">
                        {statusText[status as keyof typeof statusText]}
                    </div>
                </div>

                {onReload && (
                    <Button
                        className="border border-gray-200 bg-transparent px-2.5 py-5 text-gray-500 shadow-none hover:bg-gray-100 hover:text-gray-600"
                        disabled
                        onClick={() => onReload(document?.id)}
                    >
                        <PiRepeat className="h-5 w-5" />
                    </Button>
                )}

                <Button
                    className="border border-red-200 bg-transparent px-2.5 py-5 text-red-500 shadow-none hover:bg-red-100 hover:text-red-600"
                    onClick={() => onDelete(document?.id)}
                    disabled={status === 'Removing'}
                >
                    <PiTrash className="h-5 w-5" />
                </Button>
            </div>

            <Sheet
                open={showChunks}
                onOpenChange={(open) => {
                    setShowChunks(open)
                }}
            >
                <SheetContent className="h-full p-0 sm:max-w-2xl">
                    <SheetHeader className="shadow-xs border-b px-6 py-4">
                        <SheetTitle>{title}</SheetTitle>
                    </SheetHeader>

                    <div className="h-full space-y-8 overflow-y-auto px-6 py-3 pb-24">
                        {chunks?.map(({ id, content }: any) => (
                            <div key={id} className="h-96">
                                <Textarea
                                    className="h-full w-full resize-none text-sm text-gray-500"
                                    maxLength={1500}
                                    defaultValue={content}
                                />
                            </div>
                        ))}
                    </div>
                </SheetContent>
            </Sheet>
        </>
    )
}
