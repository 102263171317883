import { useAuth } from '@/hooks/auth'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function IndexPage() {
    const navigate = useNavigate()
    useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/dashboard',
    })

    useEffect(() => {
        navigate('/auth/login')
    }, [])

    return <></>
}
