import { axios } from "@/utils";

const createDocument = async ({ embeddingId, type, document }: any) => {
    return axios.post(`api/v1/embeddings/${embeddingId}/documents/${type}`, document).then((res) => res.data);
}

const updateDocument = async ({ type, document }: any) => {
    return axios.put(`api/v1/documents/${document.id}/${type}`, document).then((res) => res.data);
}

const deleteDocument = async (id: string) => {
    return axios.delete(`api/v1/documents/${id}`).then((res) => res.data);
};

export { createDocument, updateDocument, deleteDocument };
