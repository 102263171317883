import { InputError } from '@/components/ui/input-error'
import { Input } from '@/components/ui/input-field'
import { useAuth } from '@/hooks/auth'
import { Label } from '@radix-ui/react-label'
import { useState, FormEventHandler } from 'react'
import { SessionStatus } from './components/session-status'
import { LoaderButton } from '@/components/ui/loader-button'

type Errors = {
    email?: string[]
}

const ForgotPassword = () => {
    const { forgotPassword } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/dashboard',
    })

    const [email, setEmail] = useState<string>('')
    const [errors, setErrors] = useState<Errors>({})
    const [status, setStatus] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const submitForm: FormEventHandler = (event) => {
        event.preventDefault()

        setLoading(true)
        forgotPassword({ email, setErrors, setStatus })
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }

    console.log('status', status)

    return (
        <div className="relative flex min-h-[calc(100vh-80px)] flex-col items-center justify-center overflow-hidden">
            <div className="m-auto w-full lg:max-w-2xl">
                <div className="mx-2">
                    <div className="mb-4 text-sm text-gray-600 dark:text-gray-400">
                        Forgot your password? No problem. Just let us know your
                        email address and we will email you a password reset
                        link that will allow you to choose a new one.
                    </div>

                    {/* Session Status */}
                    <SessionStatus className="mb-4" status={status} />

                    <form onSubmit={submitForm}>
                        {/* Email Address */}
                        <div className="space-y-2">
                            <Label htmlFor="email">Email</Label>
                            <Input
                                id="email"
                                type="email"
                                name="email"
                                value={email}
                                className="mt-1 block w-full"
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                required
                                autoFocus
                            />

                            <InputError
                                messages={errors?.email}
                                className="mt-2"
                            />
                        </div>

                        <div className="mt-4 flex items-center justify-end">
                            <LoaderButton
                                type="submit"
                                className="w-full select-none py-5"
                                isLoading={loading}
                                disabled={loading}
                            >
                                Email Password Reset Link
                            </LoaderButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
