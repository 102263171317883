import { Layout } from './components/data-layout'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getDocuments, uploadDocument } from '@/services/embedding'
import { useState } from 'react'
import { deleteDocument } from '@/services/documents'

import { Document } from '@/components/shared/document'
import { notifyError, notifySuccess } from '@/utils/toast'
import { projectStore, setEmbeddingStatus } from '@/stores/projects.store'
import { FileSelect } from '@/components/shared/files-select'
import { nanoid } from '@/utils'
import { useFileReader } from '@/hooks/file-reader'

export function ProjectDataFilesPage() {
    const currentProject = projectStore((state: any) => state.currentProject)
    const [documents, setDocuments] = useState<any[]>([])
    const [inputKey, setInputKey] = useState(nanoid())
    const { readFile } = useFileReader()

    const embeddingId = currentProject?.embedding.id

    const { isLoading: loadingDocuments } = useQuery({
        queryKey: [`embedding-${embeddingId}-data-files`],
        queryFn: () => getDocuments(embeddingId as string, 'file'),
        onSuccess: (data:any) => setDocuments(data?.data),
    })

    const uploadMutation = useMutation({
        mutationKey: ['upload-document'],
        mutationFn: uploadDocument,
    })

    const deleteMutation = useMutation({
        mutationKey: ['delete-document'],
        mutationFn: deleteDocument,
    })

    const handleFileChange = (files: FileList | null) => {
        if (!files) return

        Array.from(files).forEach(async (file) => {
            try {
                const content = await readFile(file)
                handleFileUpload(content, file)
            } catch (error: any) {
                notifyError(
                    `Error processing file ${file.name}: ${error.message}`
                )
            }
        })

        setInputKey(nanoid())
    }

    const handleFileUpload = async (content: string, file: File) => {
        const formData = new FormData()
        const blob = new Blob([content], { type: 'text/plain' })

        formData.append('file', blob, 'content.txt')
        formData.append('title', file.name)
        formData.append('mime_type', file.type)
        formData.append('char_count', content.length.toString())

        uploadMutation.mutate(
            {
                embeddingId: embeddingId as string,
                data: formData,
            },
            {
                onSuccess: (data) => {
                    console.log('Uploaded file', { data: data.data, documents })

                    setDocuments((prev) => [data.data, ...prev])

                    // FIXME
                    setEmbeddingStatus('Pending')

                    notifySuccess('File uploaded successfully')
                },
                onError: (error) => {
                    console.log(error)
                    notifyError('Something went wrong. Please try again later.')
                },
            }
        )
    }

    const handleFileDelete = (documentId: string) => {
        if (!confirm('Are you sure you want to delete this file?')) {
            return
        }

        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                setDocuments((prev) =>
                    prev.filter((document: any) => document?.id !== documentId)
                )

                notifySuccess('File is being deleted')
            },
            onError: (error) => {
                console.log(error)
                notifyError('Something went wrong. Please try again later.')
            },
        })
    }

    return (
        <Layout>
            <FileSelect
                inputKey={inputKey}
                onChange={handleFileChange}
                multiple
            />

            {uploadMutation.isLoading && (
                <div className="rounded-md bg-slate-50 px-2.5 py-1.5 text-center text-sm">
                    <p>Uploading...</p>
                </div>
            )}

            {/* Include files */}
            <div className="mt-[30px]">
                <div className="mb-1 flex items-center justify-between">
                    <p className="mb-1 block font-medium capitalize text-primary">
                        Included Files
                    </p>
                </div>

                {loadingDocuments ? (
                    <>Loading...</>
                ) : (
                    <div className="mb-6 w-full space-y-2">
                        {documents?.length ? (
                            documents?.map((document: any) => (
                                <Document
                                    key={document?.id}
                                    document={document}
                                    onDelete={handleFileDelete}
                                />
                            ))
                        ) : (
                            <div className="text-center text-sm text-slate-500">
                                <p>No files uploaded yet.</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    )
}
