import {
    Link,
    Outlet,
    matchPath,
    useLocation,
    useParams,
} from 'react-router-dom'
import { getProject } from '@/services/projects'
import { useQuery } from '@tanstack/react-query'
import { projectStore } from '@/stores/projects.store'
import { notifyError } from '@/utils/toast'

export function ProjectLayout() {
    const { projectId } = useParams()
    const { pathname } = useLocation()

    const { isLoading } = useQuery({
        queryKey: [`project-${projectId}`],
        queryFn: () => getProject(projectId as string),
        onSuccess(data) {
            setCurrentProject(data.data)
        },
        onError: (error) => {
            console.error(error)
            notifyError('Something went wrong. Please try again later.')
        },
    })

    const currentProject = projectStore((state: any) => state.currentProject)
    const setCurrentProject = projectStore(
        (state: any) => state.setCurrentProject
    )

    const navLinks = [
        {
            label: 'Overview',
            href: `/p/${projectId}`,
            active: pathname.endsWith(`/${projectId}`),
        },
        {
            label: 'Chat Sessions',
            href: `/p/${projectId}/chat-sessions`,
            active: pathname.endsWith(`/${projectId}/chat-sessions`),
        },
        {
            label: 'Data Source',
            href: `/p/${projectId}/data/files`,
            active: !!matchPath(`/p/${projectId}/data/*`, pathname),
        },
        {
            label: 'Settings',
            href: `/p/${projectId}/settings`,
            active: pathname.endsWith('/settings'),
        },
    ]

    const navLinkClass =
        'inline-flex items-center justify-center whitespace-nowrap py-1 text-sm relative h-9 border-b border-b-transparent bg-transparent pb-2 text-slate-500 data-[active=true]:text-slate-900 data-[active=true]:border-b-slate-900'

    return (
        <div className="container py-10">
            <h1 className="flex items-center space-x-4 text-3xl font-light text-slate-400">
                <img
                    src={currentProject?.profile_photo_url}
                    alt={currentProject?.name}
                    className="h-10 w-10 rounded-full border"
                />

                <span>
                    {isLoading || !currentProject
                        ? 'Loading...'
                        : currentProject?.name}
                </span>
            </h1>

            <div className="py-6">
                <div className="inline-flex h-9 w-full items-center justify-start space-x-8 rounded-none border-b bg-transparent text-slate-500">
                    {navLinks.map((navLink) => {
                        return (
                            <Link
                                to={navLink.href}
                                key={navLink.href}
                                className={navLinkClass}
                                data-active={navLink.active}
                            >
                                {navLink.label}
                            </Link>
                        )
                    })}
                </div>
            </div>

            <Outlet />
        </div>
    )
}
