import { cn } from '@/utils'
import { HTMLAttributes } from 'react'
interface Props extends HTMLAttributes<HTMLParagraphElement> {
    messages?: string[] | string
}

export const InputError = ({
    messages = [],
    className = '',
    ...props
}: Props) => {
    if (!Array.isArray(messages)) {
        messages = [messages]
    }

    return (
        <>
            {messages?.length > 0 && (
                <>
                    {messages.map((message, index) => (
                        <p
                            {...props}
                            className={cn(
                                'mt-2 text-sm text-red-600',
                                className
                            )}
                            key={index}
                        >
                            {message}
                        </p>
                    ))}
                </>
            )}
        </>
    )
}
