import { axios } from "@/utils";

const fetchUrl = async ({ url, signal = undefined }: { url: string, signal: AbortSignal | undefined }) => {
    return axios.get('api/v1/scrapper/fetch-url', {
        params: { url },
        signal
    }).then((res) => res.data);
};

export { fetchUrl };
