export const useConfig = () => {
    const appEnv = import.meta.env.VITE_APP_ENV

    const app = {
        env: appEnv,
        isLocal: () => appEnv === 'local',
        isProduction: () => appEnv === 'production',
    }

    const scrapping = {
        maxProcessTime: import.meta.env.VITE_SCRAPPER_MAX_PROCESS_TIME || 60 * 1,
    }

    const broadcaster = {
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        host: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_CLUSTER}.pusher.com`,
        wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
        wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
        tls: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
        transports: ['ws', 'wss'],
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    }

    return {
        app,
        scrapping,
        broadcaster,
    }
}
