import { axios } from "@/utils";

// -------- Documents --------
const getDocuments = async (embeddingId: string, type: string) => {
    return axios.get(`api/v1/embeddings/${embeddingId}/documents`, {
        params: {
            type,
        },
    }).then((res) => res.data);
};

const uploadDocument = async ({ embeddingId, data }: { embeddingId: string; data: FormData; }) => {
    return axios.post(`api/v1/embeddings/${embeddingId}/documents/file`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => res.data);
};

const processEmbedding = async (embeddingId: string) => {
    return axios.post(`api/v1/embeddings/${embeddingId}`).then((res) => res.data);
};

export {
    getDocuments,
    uploadDocument,
    processEmbedding,
};
