import { Outlet } from "react-router-dom";

export function AuthLayout() {
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <Outlet />
            </div>
        </div>
    );
}
