import { axios } from "@/utils";

// -------- Projects --------
const getProjects = async () => {
    return axios.get("api/v1/projects").then((res) => res.data);
};

const getProject = async (projectId: string) => {
    return axios.get(`api/v1/projects/${projectId}`).then((res) => res.data);
};

const createProject = async (data: any) => {
    return axios.post(`api/v1/projects`, data).then((res) => res.data);
};

const updateProject = async (project: any) => {
    return axios.put(`api/v1/projects/${project.id}`, project).then((res) => res.data);
};

const deleteProject = async (projectId: string) => {
    return axios.delete(`api/v1/projects/${projectId}`).then((res) => res.data);
}

// -------- Chat Sessions --------
const chatSessions = async (projectId: string) => {
    return axios.get(`api/v1/projects/${projectId}/chat-sessions`).then((res) => res.data);
};

const getChatSessionMessages = async (sessionId: any) => {
    return axios.get(`api/v1/chat-sessions/${sessionId}`).then((res) => res.data);
}

// -------- Assistant --------
const getAssistant = async (projectId: string) => {
    return axios.get(`api/v1/projects/${projectId}/assistant`).then((res) => res.data);
};

const updateAssistant = async ({ projectId, data }: { projectId: string; data: any; }) => {
    return axios.put(`api/v1/projects/${projectId}/assistant`, data).then((res) => res.data);
}

const updateAssistantIcons = async ({ projectId, data }: { projectId: string; data: FormData; }) => {
    return axios.post(`api/v1/projects/${projectId}/assistant/icons`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => res.data);
}

// -------- Chatbot --------
const updateChatbot = async ({ projectId, data }: { projectId: string; data: any; }) => {
    return axios.put(`api/v1/projects/${projectId}/chatbot`, data).then((res) => res.data);
};

export {
    getProjects,
    getProject,
    createProject,
    updateProject,
    deleteProject,
    chatSessions,
    getChatSessionMessages,
    getAssistant,
    updateAssistant,
    updateAssistantIcons,
    updateChatbot,
};
