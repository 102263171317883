import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { router } from '@/router'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from '@/components/ui/sonner'

export function Providers() {
    return (
        <QueryClientProvider client={new QueryClient()}>
            <RouterProvider router={router} />
            <Toaster />
        </QueryClientProvider>
    )
}
