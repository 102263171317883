import { createBrowserRouter } from 'react-router-dom'

import { AuthLayout } from './components/layouts/auth-layout'
import { AppLayout } from './components/layouts/app-layout'

import { LoginPage } from './pages/auth/login'
import { RegisterPage } from './pages/auth/register'

import { IndexPage } from './pages'

import { ProjectDataPage } from './pages/dashboard/project/documents'
import { ProjectChatbotPage } from './pages/dashboard/project/settings'
import { ProjectDataFilesPage } from './pages/dashboard/project/documents/files'
import { ProjectDataQnAPage } from './pages/dashboard/project/documents/qna'
import { ProjectDataTextsPage } from './pages/dashboard/project/documents/texts'

import { ProfileSettingsPage } from './pages/dashboard/settings'
import { TeamsSettingPage } from './pages/dashboard/settings/teams'
import { ProjectLayout } from './components/layouts/project-layout'
import { TeamBillingSettingPage } from './pages/dashboard/settings/team/billing'
import { TeamIntegrationsSettingPage } from './pages/dashboard/settings/team/integrations'
import { SettingLayout } from './pages/dashboard/settings/components/layout'
import { TeamSettingPage } from './pages/dashboard/settings/team'
import { NotFoundPage } from './pages/errors/404'
import ForgotPassword from './pages/auth/forgot-password'
import VerifyEmail from './pages/auth/verify-email'
import { SecuritySettingsPage } from './pages/dashboard/settings/security'
import { TeamMemberSettingPage } from './pages/dashboard/settings/team/team-members'

export const router = createBrowserRouter([
    {
        path: '',
        index: true,
        element: <IndexPage />,
        errorElement: <NotFoundPage />,
    },

    // Auth
    {
        path: 'auth',
        element: <AuthLayout />,
        children: [
            {
                path: 'login',
                element: <LoginPage />,
            },
            {
                path: 'register',
                element: <RegisterPage />,
            },
            {
                path: 'forgot-password',
                element: <ForgotPassword />,
            },
            {
                path: 'verify-email',
                element: <VerifyEmail />,
            },
            {
                path: 'email/verify/:id/:hash',
                element: <VerifyEmail />,
            },
        ],
    },

    // Dahsboard
    {
        path: '',
        element: <AppLayout />,
        children: [
            {
                path: 'dashboard',
                lazy: () => import('./pages/dashboard'),
            },

            // Project
            {
                path: 'new-project',
                lazy: () => import('./pages/dashboard/new-project'),
            },
            {
                path: 'p/:projectId',
                element: <ProjectLayout />,
                children: [
                    {
                        path: '',
                        lazy: () => import('./pages/dashboard/project'),
                    },
                    {
                        path: 'chat-sessions',
                        lazy: () =>
                            import('./pages/dashboard/project/chat-sessions'),
                    },
                    // {
                    //     path: 'leads',
                    //     lazy: () =>
                    //         import('./pages/dashboard/project/overview/leads'),
                    // },
                    {
                        path: 'data',
                        children: [
                            {
                                path: '',
                                element: <ProjectDataPage />,
                            },
                            {
                                path: 'files',
                                element: <ProjectDataFilesPage />,
                            },
                            {
                                path: 'qna',
                                element: <ProjectDataQnAPage />,
                            },
                            {
                                path: 'texts',
                                element: <ProjectDataTextsPage />,
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        element: <ProjectChatbotPage />,
                    },
                ],
            },

            // Setting
            {
                path: 'settings',
                element: <SettingLayout />,
                children: [
                    {
                        path: '',
                        element: <ProfileSettingsPage />,
                    },
                    {
                        path: 'security',
                        element: <SecuritySettingsPage />,
                    },
                    {
                        path: 'teams',
                        element: <TeamsSettingPage />,
                    },
                ],
            },

            // Team Setting
            {
                path: 'settings/team',
                element: <SettingLayout />,
                children: [
                    {
                        path: 'general',
                        element: <TeamSettingPage />,
                    },
                    {
                        path: 'members',
                        element: <TeamMemberSettingPage />,
                    },
                    {
                        path: 'integrations',
                        element: <TeamIntegrationsSettingPage />,
                    },
                    {
                        path: 'billing',
                        element: <TeamBillingSettingPage />,
                    },
                ],
            },
        ],
    },
])
