import { Button } from '@/components/ui/button'
import { teamStore } from '@/stores/teams.store'
import { PiMagnetDuotone } from 'react-icons/pi'

export function TeamsSettingPage() {
    const teams = teamStore((state: any) => state.teams)

    return (
        <section>
            <div className="space-y-2">
                {teams.map((team: any, index: any) => {
                    return (
                        <div
                            key={index}
                            className="flex items-center justify-between rounded-lg border bg-white p-4"
                        >
                            <div className="flex items-center space-x-4">
                                <img
                                    className="h-10 w-10 rounded-full"
                                    src={team?.profile_photo_url}
                                    alt={team?.name}
                                />
                                <div>
                                    <div className="text-sm font-medium text-gray-900">
                                        {team.name}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center space-x-3">
                                <Button
                                    variant="outline"
                                    className="shadow-none"
                                >
                                    Manage
                                </Button>
                                <Button
                                    variant="outline"
                                    className="shadow-none"
                                    size="icon"
                                >
                                    <PiMagnetDuotone />
                                </Button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}
