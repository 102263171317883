import React from 'react'
import { Button, ButtonProps } from '../ui/button'
import { TbLoader2 } from 'react-icons/tb'

type LoaderButtonProps = ButtonProps & {
    isLoading?: boolean
    icon?: React.ElementType
    children: React.ReactNode
}

export const LoaderButton = React.forwardRef<
    HTMLButtonElement,
    LoaderButtonProps
>(({ isLoading, icon: Icon, children, ...props }, ref) => {
    if (isLoading) {
        return (
            <Button ref={ref} disabled={isLoading} {...props}>
                <TbLoader2 className="mr-2 h-4 w-4 animate-spin" />
                {children}
            </Button>
        )
    }

    return (
        <Button ref={ref} disabled={isLoading} {...props}>
            {Icon ? (
                <>
                    <Icon size={18} className="mr-3" />
                    {children}
                </>
            ) : (
                children
            )}
        </Button>
    )
})
LoaderButton.displayName = 'LoaderButton'
