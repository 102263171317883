import useSWR from 'swr'
import { useEffect } from 'react'
import axios, { csrf } from '@/utils/axios'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'

declare type AuthMiddleware = 'auth' | 'guest'

interface IUseAuth {
    middleware: AuthMiddleware
    redirectIfAuthenticated?: string
}

interface IApiRequest {
    setErrors: React.Dispatch<React.SetStateAction<any>>
    setStatus?: React.Dispatch<React.SetStateAction<any>>
    [key: string]: any
}

export interface User {
    id?: number
    name?: string
    email?: string
    email_verified_at?: string
    must_verify_email?: boolean // this is custom attribute
    profile_photo_url?: string
    created_at?: string
    updated_at?: string
}

export const useAuth = ({ middleware, redirectIfAuthenticated }: IUseAuth) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [searchParams] = useSearchParams()

    const { data: user, error, mutate } = useSWR<User>('/api/v1/me', () =>
        axios
            .get('/api/v1/me')
            .then(res => res.data.user)
            .catch(error => {
                if (error.response.status !== 409) throw error

                navigate('/auth/verify-email')
            }),
    )

    const register = async (args: IApiRequest) => {
        const { setErrors, ...props } = args

        await csrf()

        setErrors([])

        axios
            .post('/auth/register', props)
            .then(() => mutate())
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(error.response.data.errors)
            })
    }

    const login = async (args: IApiRequest) => {
        const { setErrors, setStatus, ...props } = args

        await csrf()

        setErrors([])
        if (setStatus) {
            setStatus(null)
        }

        axios
            .post('/auth/login', props)
            .then(() => mutate())
            .catch(error => {
                if (error.response.status !== 422) throw error
                setErrors(error.response.data.errors)
            })
    }

    const forgotPassword = async (args: IApiRequest) => {
        const { setErrors, setStatus, email } = args
        await csrf()

        setErrors([])
        setStatus && setStatus(null)

        axios
            .post('/auth/forgot-password', { email })
            .then(response => setStatus && setStatus(response.data.message))
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(error.response.data.errors)
            })
    }

    const resetPassword = async (args: IApiRequest) => {
        const { setErrors, setStatus, ...props } = args
        await csrf()

        setErrors([])
        setStatus && setStatus(null)

        axios
            .post('/auth/reset-password', { token: searchParams.get('token'), ...props })
            .then(response =>
                navigate('/auth/login?reset=' + btoa(response.data.status)),
            )
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(error.response.data.errors)
            })
    }

    const resendEmailVerification = async (args: IApiRequest) => {
        const { setStatus } = args

        axios
            .post('/auth/email/verification-notification')
            .then(response => { setStatus && setStatus(response.data.status ?? 'verification-link-sent') })
    }

    const logout = async () => {
        await axios.post('/auth/logout').then(() => mutate())

        // navigate('/auth/login')

        window.location.pathname = '/auth/login'
    }

    useEffect(() => {
        if (middleware === 'guest' && redirectIfAuthenticated && user) {
            navigate(redirectIfAuthenticated)
        }

        if (
            pathname === '/auth/verify-email'
            && user?.email_verified_at
        ) {
            navigate(redirectIfAuthenticated ?? '/')
        }

        if (middleware === 'auth' && error) {
            logout()
        }
    }, [user, error])

    return {
        user,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
    }
}
