import { Card, CardContent, CardFooter } from '@/components/ui/card'
import { Input } from '@/components/ui/input-field'
import { useAuth } from '@/hooks/auth'
import { Label } from '@/components/ui/label'
import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { InputError } from '@/components/ui/input-error'
import { LoaderButton } from '@/components/ui/loader-button'

type Errors = {
    name?: string[]
    email?: string[]
    password?: string[]
    password_confirmation?: string[]
}

export function RegisterPage() {
    const { register } = useAuth({
        middleware: 'guest',
        redirectIfAuthenticated: '/dashboard',
    })

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')

    const [errors, setErrors] = useState<Errors>({})
    const [loading, setLoading] = useState(false)

    const submitForm = (event: FormEvent) => {
        event.preventDefault()

        setLoading(true)
        register({
            name,
            email,
            password,
            password_confirmation: passwordConfirmation,
            setErrors,
        })
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }

    return (
        <div className="relative flex min-h-[calc(100vh-80px)] flex-col items-center justify-center overflow-hidden">
            <div className="m-auto w-full lg:max-w-lg">
                <div className="mb-[30px] text-center">
                    <h1 className="mb-2 text-2xl font-semibold tracking-[-1px] text-slate-900 md:text-4xl">
                        Register
                    </h1>
                    <p className="mx-auto tracking-[-0.3px] text-slate-500">
                        Please enter your details to register
                    </p>
                </div>

                <Card className="mx-auto max-w-md bg-white">
                    <form onSubmit={submitForm}>
                        <CardContent className="grid gap-4 p-6">
                            {/* Name */}
                            <div className="space-y-2">
                                <Label htmlFor="name">Name</Label>

                                <Input
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(event: any) =>
                                        setName(event.target.value)
                                    }
                                    placeholder="Alan Smith"
                                    className="placeholder:text-slate-300"
                                    required
                                    autoFocus
                                />

                                <InputError
                                    messages={errors?.name}
                                    className="mt-2"
                                />
                            </div>

                            {/* Email */}
                            <div className="space-y-2">
                                <Label htmlFor="email">Email</Label>
                                <Input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(event) =>
                                        setEmail(event.target.value)
                                    }
                                    placeholder="hello@chatever.ai"
                                    className="placeholder:text-slate-300"
                                    required
                                    autoFocus
                                />

                                <InputError
                                    messages={errors?.email}
                                    className="mt-2"
                                />
                            </div>

                            {/* Password */}
                            <div className="space-y-2">
                                <Label htmlFor="password">Password</Label>

                                <Input
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(event: any) =>
                                        setPassword(event.target.value)
                                    }
                                    placeholder="p@ssw0rd"
                                    className="placeholder:text-slate-300"
                                    required
                                    autoComplete="new-password"
                                />

                                <InputError
                                    messages={errors?.password}
                                    className="mt-2"
                                />
                            </div>

                            {/* Confirm Password */}
                            <div className="space-y-2">
                                <Label htmlFor="passwordConfirmation">
                                    Confirm Password
                                </Label>

                                <Input
                                    id="passwordConfirmation"
                                    type="password"
                                    value={passwordConfirmation}
                                    onChange={(event: any) =>
                                        setPasswordConfirmation(
                                            event.target.value
                                        )
                                    }
                                    placeholder="Confirm Password"
                                    className="placeholder:text-slate-300"
                                    required
                                />

                                <InputError
                                    messages={errors?.password_confirmation}
                                    className="mt-2"
                                />
                            </div>

                            <LoaderButton
                                type="submit"
                                className="w-full select-none py-5"
                                isLoading={loading}
                                disabled={loading}
                            >
                                Register
                            </LoaderButton>
                        </CardContent>
                    </form>

                    <CardFooter className="flex flex-col space-y-2 px-4">
                        <p className="flex items-center justify-center space-x-2 text-center text-sm">
                            <span className="text-slate-500">
                                Already have an Account?
                            </span>
                            <Link
                                to="/auth/login"
                                className=" cursor-pointer font-medium underline"
                            >
                                Login
                            </Link>
                        </p>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}
