import { projectStore } from '@/stores/projects.store'
import { PiNotebookDuotone } from 'react-icons/pi'

export default function DocumentsOverview() {
    const currentProject = projectStore((state: any) => state.currentProject)

    return (
        <div className="mb-8 items-start rounded-xl border border-border bg-slate-700 p-6 font-light text-slate-300 sm:flex sm:space-x-4">
            <div className="mb-3 flex h-9 w-9 items-center justify-center rounded-full bg-slate-900 sm:mb-0">
                <PiNotebookDuotone className="text-xl" />
            </div>
            <div className="flex-1">
                {currentProject?.embedding?.documents_sum_char_count > 0 ? (
                    <>
                        <p className="mb-4 text-base">
                            Total detected characters:{' '}
                            {
                                currentProject?.embedding
                                    ?.documents_sum_char_count
                            }
                        </p>
                        <p className="mb-2 text-slate-25">Included sources:</p>
                        <ul className="grid gap-1.5 text-white lg:grid-cols-2">
                            {currentProject?.embedding?.document_overview?.map(
                                (overview: any, index: number) => (
                                    <li
                                        className="mb-1.5 flex space-x-6 text-sm"
                                        key={index}
                                    >
                                        <span>{overview.type}:</span>
                                        <p className="space-x-4">
                                            <span>
                                                {overview.fileCount} files
                                            </span>
                                            <span>
                                                ({overview.charCount} chars)
                                            </span>
                                        </p>
                                    </li>
                                )
                            )}
                        </ul>
                    </>
                ) : (
                    <div className="ml-4 mt-1.5">
                        <p className="text-base text-white">
                            No documents have been added to this project yet
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}
