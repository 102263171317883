import { Button } from '@/components/ui/button'
import { Layout } from './components/data-layout'
import { Textarea } from '@/components/ui/textarea'
import { Card, CardContent } from '@/components/ui/card'
import { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getDocuments } from '@/services/embedding'
import {
    createDocument,
    deleteDocument,
    updateDocument,
} from '@/services/documents'
import { notifyError, notifySuccess } from '@/utils/toast'
import { nanoid } from '@/utils'
import { projectStore, setEmbeddingStatus } from '@/stores/projects.store'
import { Document } from '../../../../components/shared/document'

const TextDocument = ({ document, onDelete, onSave }: any) => {
    return (
        <>
            {document.isNew ? (
                <NewDocument
                    document={document}
                    onDelete={onDelete}
                    onSave={onSave}
                />
            ) : (
                <div>
                    <Document document={document} onDelete={onDelete} />
                </div>
            )}
        </>
    )
}

const NewDocument = ({ document, onDelete, onSave }: any) => {
    const { content } = document

    return (
        <Card>
            <CardContent className="p-3">
                <div className="space-y-3">
                    <Textarea
                        placeholder="Type your content here..."
                        defaultValue={content}
                        rows={6}
                        onChange={(e) => {
                            document.content = e.target.value
                        }}
                    />

                    <div className="flex items-center justify-between">
                        <span className="text-sm text-gray-700">
                            {content?.length} characters
                        </span>
                        <div className="space-x-2">
                            <Button
                                variant="ghost"
                                className="text-destructive hover:text-destructive"
                                onClick={onDelete}
                            >
                                Delete
                            </Button>
                            <Button onClick={onSave}>Save</Button>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export function ProjectDataTextsPage() {
    const currentProject = projectStore((state: any) => state.currentProject)

    const embeddingId = currentProject?.embedding.id

    const [documents, setDocuments] = useState<any[]>([
        {
            id: nanoid(),
            title: 'Untitled Document',
            content: '',
            isNew: true,
        },
    ])

    const { isLoading: loadingDocuments } = useQuery({
        queryKey: [`embedding-${embeddingId}-documents-texts`],
        queryFn: () => getDocuments(embeddingId as string, 'text'),
        onSuccess: (data) => data?.data.length && setDocuments(data?.data),
    })

    const deleteMutation = useMutation({
        mutationFn: deleteDocument,
    })

    const createMutation = useMutation({
        mutationFn: createDocument,
    })

    const updateMutation = useMutation({
        mutationFn: updateDocument,
    })

    const handleDelete = (document: any) => {
        const { id, isNew } = document

        if (!confirm('Are you sure you want to delete this content?')) {
            return
        }

        if (isNew) {
            setDocuments((prev) =>
                prev.filter((document: any) => document?.id !== id)
            )
            return
        }

        deleteMutation.mutate(id, {
            onSuccess: () => {
                setDocuments((prev) =>
                    prev.filter((document: any) => document?.id !== id)
                )

                notifySuccess('File is being deleted')
            },
            onError: (error) => {
                console.error(error)
                notifyError('Something went wrong. Please try again later.')
            },
        })
    }

    const handleSave = (document: any) => {
        if (document.isNew) {
            createMutation.mutate(
                { embeddingId, type: 'text', document },
                {
                    onSuccess: (data) => {
                        setDocuments((prev) => [
                            ...prev.filter((doc) => doc.id !== document.id),
                            data?.data,
                        ])

                        // FIXME
                        setEmbeddingStatus('Pending')

                        notifySuccess('File created successfully')
                    },
                    onError: (error) => {
                        console.error(error)
                        notifyError(
                            'Something went wrong. Please try again later.'
                        )
                    },
                }
            )
            return
        }

        updateMutation.mutate(
            { document, type: 'text' },
            {
                onSuccess: () => {
                    notifySuccess('File updated successfully')
                },
                onError: (error) => {
                    console.error(error)
                    notifyError('Something went wrong. Please try again later.')
                },
            }
        )
    }

    const handleAddNew = () => {
        setDocuments((prev) => [
            ...prev,
            {
                id: nanoid(),
                title: 'Untitled Document',
                content: '',
                isNew: true,
            },
        ])
    }

    return (
        <Layout>
            {loadingDocuments ? (
                <>Loading...</>
            ) : (
                <div className="space-y-6">
                    {documents.map((document) => (
                        <TextDocument
                            key={document.id}
                            document={document}
                            onDelete={() => handleDelete(document)}
                            onSave={() => handleSave(document)}
                        />
                    ))}
                </div>
            )}

            <div className="flex items-center justify-between py-6">
                <Button
                    variant="ghost"
                    className="text-primary hover:text-primary"
                    onClick={() => handleAddNew()}
                >
                    Add New
                </Button>
            </div>
        </Layout>
    )
}
